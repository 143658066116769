export enum AuthActionTypes {
    SIGN_IN= 'SIGN_IN',
    SIGN_OUT= 'SIGN_OUT',
    SIGN_UP= 'SIGN_UP',
    RESTORE_TOKEN=  'RESTORE_TOKEN',
}

export enum CalUserActionTypes {
    GET_CAL_USER = 'GET_CAL_USER',
    GET_CAL_PROF_USER = 'GET_CAL_PROF_USER',
    SET_CAL_USER_EVENT= 'SET_CAL_USER_EVENT',
    GET_CAL_USER_EVENT= 'GET_CAL_USER_EVENT',
    DEL_CAL_USER_EVENT= 'DEL_CAL_USER_EVENT',
    UPD_CAL_USER_EVENT= 'UPD_CAL_USER_EVENT',
}

// En algún archivo de tipos o directamente en tu componente
interface CalendarData {
  "0": string[]; // Cadena JSON con la propiedad "hora_ini" y "hora_fin"
  "@JSON_FINAL": string[]; // Cadena JSON con la misma estructura que la propiedad "0"
}

export type CalState = {
    user: CalendarData | null;
    isLoading: boolean;
    isError: boolean;
    error: string | null;
    token: string | null; // Agrega la propiedad token
  }

  export enum GetInfoHoraReservaTypes {
    GET_GET_INFO_HORA_RESERVA = 'GET_INFO_HORA_RESERVA',
  }
  
  export type GetInfoHoraReservaData = {
    Empresa: string;
    Termino: string;
    Localidad: string;
    Sede: string;
    Servicio: string;
    Estado: string;
    Cliente:string;
    Inicio:string;
    Fecha:string;
    ClienteRut:string;
    cod_loc: string;
  
  }
  
  
  export type GetInfoHoraReservaState = {
    infoReserva: GetInfoHoraReservaData[] | null;
    isLoading: boolean;
    isError: boolean;
    error: string | null | unknown;
  };

  export enum UpdStateHoraTypes {
    UPD_STATE_HORA = 'UPD_STATE_HORA',
  }
  
  export type UpdStateHoraData = {
    Empresa: string;
    Termino: string;
    Localidad: string;
    Sede: string;
    Servicio: string;
    Estado: string;
    Cliente:string;
  
  }
  
  
  export type UpdStateHoraState = {
    updStateReserva: UpdStateHoraData[] | null;
    isLoading: boolean;
    isError: boolean;
    error: string | null | unknown;
  };

  export enum DelHoraReservaTypes {
    DEL_HORA_RESERVA = 'DEL_HORA_RESERVA',
  }
  
  export type DelHoraReservaData = {
    Empresa: string;
    Termino: string;
    Localidad: string;
    Sede: string;
    Servicio: string;
    Estado: string;
    Cliente:string;
  
  }
  
  
  export type DelHoraReservaState = {
    delHoraReserva: DelHoraReservaData[] | null;
    isLoading: boolean;
    isError: boolean;    
    isFinish:boolean;
    error: string | null | unknown;
  };

  export enum AddImageServTypes {
    ADD_IMAGE_SERV = 'ADD_IMAGE_SERV',
  }
  
  export type AddImageServData = {
    Empresa: string;
    Termino: string;
    Localidad: string;
    Sede: string;
    Servicio: string;
    Estado: string;
    Cliente:string;
  
  }
  
  
  export type AddImageServState = {
    AddImageServ: AddImageServData | null;
    isLoading: boolean;
    isError: boolean;    
    isFinish:boolean;
    error: string | null | unknown;
  };

export type RootState = {
    auth: any;
    calUser: CalState;
    InfoReserva: GetInfoHoraReservaState;
    UpdStateHora: UpdStateHoraState;
}