import { Dispatch } from 'redux';
import { getCalUser } from './CalendarUser/GetCalendar/calUserSlice';
import { GetImageProfilePsoFUNC, GetDescriptionPsoFUNC, GetGalleryImagesPsoFUNC, SetImagePerfilPsoFUNC } from './Perfil_pso/Perfil_pso';
import { GetTYC_CfFUNC, checkUserRegistration } from './auth/authSlice';
// import { GetPsoPswFunc } from "./ProfilePso/ProfilePsoSlice";
import { GetStepPsoFUNC } from './Event/PSO/Gral/StepPsoSlice';
// import { GetPsoLocCalFUNC_STEP } from './FormService/InfoStep';
import { GetPsoServDispFUNC_STEP, SetLockHoraFUNC } from './Agendamiento/InfoStep';
import { GetHourDispFUNC_STEP } from './Agendamiento/InfoStep';
import { GetCfDispFUNC_STEP } from './Agendamiento/InfoStep';
import { SetPsoReservaFUNC_STEP } from './Agendamiento/InfoStep';
import { GetMenuPsoListPSwFUNC, GetMenuPsoListPsoFUNC, GetMenuPsoListPswPsoFUNC, MenuGetConexionFUNC } from './Menu/MenuSlice';
import { GetGalleryImgServFUNC, GetImageServFUNC, GetPreciosPsoListPswFUNC, SetImageServFUNC, UpdServPsoFUNC } from './Precios/Precios_pso';
import { GetImageTransferFUNC, SetImageTransferenciaFUNC } from './Agendamiento/Pagos/Transferencia/TransferenciaSlice';
import { GetDescriptionCfFUNC, GetGalleryImagesCfFUNC, GetImageProfileCfFUNC, SetImagePerfilCfFUNC } from './Perfil_cf/Perfil_cf';
// import { GetConfigFormFUNC, GetConfigPerfilCfFUNC, GetMenuConfigFUNC, GetMenuConfigPsoFUNC, GetMenuPsoConfigFUNC } from './FormService/ConfigForm';
// import { GetGenInfoFormFUNC } from './FormService/GetGenInfoForm';

type FunctionMap = {
  [key: string]: (params?: any, dispatch?: Dispatch<any>) => void; // Cambiado a Dispatch<any>
};

export const functionMap: FunctionMap = {

  // LOGIN
  checkUserRegistration: (params?: any, dispatch?: Dispatch<any>) => { // sirve para saber si esta registrado el usuario
    if (dispatch) dispatch(checkUserRegistration(params));
  },
  UserRegistration: (params?: any, dispatch?: Dispatch<any>) => { // NO TERMINADA
    if (dispatch) dispatch(checkUserRegistration(params));
  },
  GetTYC_Cf: (params?: any, dispatch?: Dispatch<any>) => { // NO TERMINADA
    if (dispatch) dispatch(GetTYC_CfFUNC(params));
  },

  // MENU PSO 
  GetMenuPsoListPso: (params?: any, dispatch?: Dispatch<any>) => { // LISTAS DE EMPRESAS ASOCIADAS
    if (dispatch) dispatch(GetMenuPsoListPsoFUNC(params))
  },
  GetMenuPsoListPsw: (params?: any, dispatch?: Dispatch<any>) => { // LISTA DE TRABAJADORES ASOCIADAS
    if (dispatch) dispatch(GetMenuPsoListPSwFUNC(params))
  },
  MenuGetConexion: (params?: any, dispatch?: Dispatch<any>) => { // LISTA DE CONEXIONES DE UNA PERSONA CON OTRAS EMPRESAS
    if (dispatch) dispatch(MenuGetConexionFUNC(params))
  },
  GetMenuPsoListPswPso: (params?: any, dispatch?: Dispatch<any>) => { // LISTA DE CONEXIONES DE UNA PERSONA CON OTRAS EMPRESAS
    if (dispatch) dispatch(GetMenuPsoListPswPsoFUNC(params))
  },

  // SERVICIOS PSO 
  GetPreciosPsoListPsw: (params?: any, dispatch?: Dispatch<any>) => { // GET PRECIO DE SERVICIOS
    if (dispatch) dispatch(GetPreciosPsoListPswFUNC(params));
  },
  SetImageServ: (params?: any, dispatch?: Dispatch<any>) => { // SET IMAGEN DEL SERIVICIO
    if (dispatch) dispatch(SetImageServFUNC(params));
  },
  GetImageServ: (params?: any, dispatch?: Dispatch<any>) => { // SET IMAGEN DEL SERIVICIO
    if (dispatch) dispatch(GetImageServFUNC(params));
  },
  GetGalleryImgServ: (params?: any, dispatch?: Dispatch<any>) => { // SET IMAGEN DEL SERIVICIO
    if (dispatch) dispatch(GetGalleryImgServFUNC(params));
  },
  UpdServPso: (params?: any, dispatch?: Dispatch<any>) => { // SET IMAGEN DEL SERIVICIO
    if (dispatch) dispatch(UpdServPsoFUNC(params));
  },

  // AGENDAMIENTO: 
  SetImageTransferencia: (params?: any, dispatch?: Dispatch<any>) => { // TRANSFERENCIA : SET IMAGE 
    if (dispatch) dispatch(SetImageTransferenciaFUNC(params))
  },
  GetImageTransferencia: (params?: any, dispatch?: Dispatch<any>) => { // TRANSFERENCIA : GET IMAGE 
    if (dispatch) dispatch(GetImageTransferFUNC(params))
  }, 
  SetLockHora: (params?: any, dispatch?: Dispatch<any>) => { // LOCK HORA  
    if (dispatch) dispatch(SetLockHoraFUNC(params))
  },
  
  // DATOS DEL PERFIL PSO
  getImageProfilePso: (params?: any, dispatch?: Dispatch<any>) => { // Imagen de perfil pso
    if (dispatch) dispatch(GetImageProfilePsoFUNC(params));
  },
  setImageProfilePso: (params?: any, dispatch?: Dispatch<any>) => { // set imagen de perfil
    if (dispatch) dispatch(SetImagePerfilPsoFUNC(params));
  },
  getDescriptionPso: (params?: any, dispatch?: Dispatch<any>) => { // descripcion
    if (dispatch) dispatch(GetDescriptionPsoFUNC(params));
  },
  getGalleryImagesPso: (params?: any, dispatch?: Dispatch<any>) => { // galeria de imagenes
    if (dispatch) dispatch(GetGalleryImagesPsoFUNC(params))
  },
  
  // DATOS DEL PERFIL CF
  getImageProfileCf: (params?: any, dispatch?: Dispatch<any>) => { // get imagen de perfil 
    if (dispatch) dispatch(GetImageProfileCfFUNC(params));
  },
  setImageProfileCf: (params?: any, dispatch?: Dispatch<any>) => { // set imagen de perfil
    if (dispatch) dispatch(SetImagePerfilCfFUNC(params));
  },
  getDescriptionCf: (params?: any, dispatch?: Dispatch<any>) => { // descripcion
    if (dispatch) dispatch(GetDescriptionCfFUNC(params));
  },
  getGalleryImagesCf: (params?: any, dispatch?: Dispatch<any>) => { // galeria de imagenes
    if (dispatch) dispatch(GetGalleryImagesCfFUNC(params))
  },

  // CALENDARIO
  getCalUser: (params?: any, dispatch?: Dispatch<any>) => { // get de serivicios por fecha
    if (dispatch) dispatch(getCalUser(params));
  },

  // Datos del perfil PSO
  GetPsoPswFunc: (params?: any, dispatch?: Dispatch<any>) => { // Trabajadores
    // if (dispatch) dispatch(GetPsoPswFunc(params));
  },
  // formularios de agendamiento o arrendamiento
  GetStepPsoFUNC: (params?: any, dispatch?: Dispatch<any>) => { // Formularios 
    if (dispatch) dispatch(GetStepPsoFUNC(params));
  },
  GetPsoLocCalFUNC_STEP: (params?: any, dispatch?: Dispatch<any>) => { // Localidades disponibles
    // if (dispatch) dispatch(GetPsoLocCalFUNC_STEP(params));
  },
  GetPsoServDispFUNC_STEP: (params?: any, dispatch?: Dispatch<any>) => { // Servicios disponibles
    if (dispatch) dispatch(GetPsoServDispFUNC_STEP(params));
  },
  GetHourDispFUNC_STEP: (params?: any, dispatch?: Dispatch<any>) => { // Horas disponibles
    if (dispatch) dispatch(GetHourDispFUNC_STEP(params));
  },
  GetCfDispFUNC_STEP: (params?: any, dispatch?: Dispatch<any>) => { // Clientes disponibles
    if (dispatch) dispatch(GetCfDispFUNC_STEP(params));
  },
  SetPsoReservaFUNC_STEP: (params?: any, dispatch?: Dispatch<any>) => { // Clientes disponibles
    if (dispatch) dispatch(SetPsoReservaFUNC_STEP(params));
  },
  GetConfigFormFUNC: (params?: any, dispatch?: Dispatch<any>) => { // Clientes disponibles
    // if (dispatch) dispatch(GetConfigFormFUNC(params));
  },
  GetMenuConfigFUNC: (params?: any, dispatch?: Dispatch<any>) => { // Clientes disponibles
    // if (dispatch) dispatch(GetMenuConfigFUNC(params));
  },
  GetConfigPerfilCfFUNC: (params?: any, dispatch?: Dispatch<any>) => { // Clientes disponibles
    // if (dispatch) dispatch(GetConfigPerfilCfFUNC(params));
  },
  GetMenuConfigPsoFUNC: (params?: any, dispatch?: Dispatch<any>) => { // Clientes disponibles
    // if (dispatch) dispatch(GetMenuConfigPsoFUNC(params));
  },
  GetMenuPsoConfigFUNC: (params?: any, dispatch?: Dispatch<any>) => { // Clientes disponibles
    // if (dispatch) dispatch(GetMenuPsoConfigFUNC(params));
  },
  GetGenInfoFormFUNC: (params?: any, dispatch?: Dispatch<any>) => { // Clientes disponibles
    // if (dispatch) dispatch(GetGenInfoFormFUNC(params));
  },
  // Puedes agregar más funciones aquí según sea necesario
};

export const dynamicAction = (functionName: string, params: any) => {
  return async (dispatch: Dispatch) => {
    if (functionMap[functionName]) {
      try {
        await functionMap[functionName](params); // Pasamos dispatch como segundo parámetro
        // No necesitamos despachar ninguna acción aquí, ya que getCalUser lo hará por nosotros
      } catch (error) {
        console.error(`Error al ejecutar la función '${functionName}':`, error);
        // Manejar cualquier error si es necesario
      }
    } else {
      console.error(`La función '${functionName}' no existe`);
    }
  };
};

