


export type GetPsoLocCalDataStep = {
  pso: string;
  localidad: string;
  sede: string;
  pso_nemo: string;
  loc_nemo: string;
  sede_nemo: string;

}


export enum GetPsoLocCalTypesStep {
  GET_INFO_USER_CAL_STEP = 'GET_INFO_USER_CAL_STEP',
}

export type GetPsoLocCalStateStep = {
  rsp: GetPsoLocCalDataStep[] | null;
  isLoading: boolean;
  isError: boolean;
  error: string | null | unknown;
};



//----------------------------------------
export type GetPsoServDispDataStep = {
  var_button: String;
  var_title: String;
  iconName: String;
  var_option: String;

}

export enum GetPsoServDispTypesStep {
  GET_PSO_SERV_DISP_STEP = 'GET_PSO_SERV_DISP_STEP',
}

export type GetPsoServDispStateStep = {
  rsp: GetPsoServDispDataStep[] | null;
  isLoading: boolean;
  isError: boolean;
  error: string | null | unknown;
};

//----------------------------------------
export type GetHourDispDataStep = {
  var_button: String;
  var_title: String;
  iconName: String;
  var_option: String;

}

export enum GetHourDispTypesStep {
  GET_HOUR_DISP_STEP = 'GET_HOUR_DISP_STEP',
}

export type GetHourDispStateStep = {
  rsp: GetHourDispDataStep[] | null;
  isLoading: boolean;
  isError: boolean;
  error: string | null | unknown;
};

//----------------------------------------
export type GetPswDispDataStep = {
  var_button: String;
  var_title: String;
  iconName: String;
  var_option: String;

}

export enum GetPswDispTypesStep {
  GET_PSW_DISP_STEP = 'GET_PSW_DISP_STEP',
}

export type GetPswDispStateStep = {
  rsp: GetPswDispDataStep[] | null;
  isLoading: boolean;
  isError: boolean;
  error: string | null | unknown;
};


//----------------------------------------
export type GetCfDispDataStep = {
  var_button: String;
  var_title: String;
  iconName: String;
  var_option: String;

}

export enum GetCfDispTypesStep {
  GET_CF_DISP_STEP = 'GET_CF_DISP_STEP',
}

export type GetCfDispStateStep = {
  rsp: GetCfDispDataStep[] | null;
  isLoading: boolean;
  isError: boolean;
  error: string | null | unknown;
};

export type RootStateStep = {
  rsp: GetPsoLocCalStateStep;
  
}


//----------------------------------------
export type SetPsoReservaData = {
  if_ok: String;
  msg_reserva: String;

}

export enum SetPsoReservaTypes {
  SET_PSO_RESERVA_STEP = 'SET_PSO_RESERVA_STEP',
}

export type SetPsoReservaState = {
  ifReserva: SetPsoReservaData | null;
  isLoading: boolean;
  isError: boolean;
  error: string | null | unknown;
};


// ------------------------------------------
export type SetLockHoraData = {
  pso: string;
  localidad: string;
  sede: string;
  pso_nemo: string;
  loc_nemo: string;
  sede_nemo: string;

}


export enum SetLockHoraTypes {
  SET_LOCK_HORA = 'SET_LOCK_HORA',
}

export type SetLockHoraState = {
  rsp: SetLockHoraData| null;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;
};

