// store.ts
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { authSlice, GetTYC_CfSlice } from './auth/authSlice';
import variablesSlice from './GlobalVar/variablesSlice';

// PerfilPSO
import {GetImageProfilePsoSlice, GetDescriptionPsoSlice,  GetGalleryImagesPsoSlice} from './Perfil_pso/Perfil_pso';


import {calUserSlice, GetInfoHoraReservaSlice, UpdStateHoraSlice, DelHoraReservaSlice, AddImageServSlice} from './CalendarUser/GetCalendar/calUserSlice';
// import {ProfileGralSlice, GetPsoUserSlice} from './ProfileGral/ProfileGralSlice';
// import {GetPsfPsoUserSlice, SetPsfPsoUserSlice, GetPsoPswSlice} from './ProfilePso/ProfilePsoSlice';
// import {calProfUserSlice, calServUserSlice, calPswUserSlice, calMatchUserSlice, SetHoraPswSlice} from './CalendarUser/SetEvent/EventServ/calUserSetServSlice';
// import {GetPsoLocCalSlice} from './CalendarUser/InfoCalendar/infoCalUser';
import { GetStepPsoSlice } from './Event/PSO/Gral/StepPsoSlice';

//imagenes  
// import {GetImageProfilePSWSlice, SetImageSlice} from './ManImage/ImageSlice';
// import {DelImageSlice} from './ManImage/ImageSlice';
// import {GetImageAllFormatSlice} from './ManImage/ImageSlice';

//FORMULARIO DE STEP PARA SERVICIOS
// import { SLICE_GET_INFO_STEP } from './FormService/GetInfoStep';
// import { GetPsoLocCalSlice_STEP } from './Agendamiento/InfoStep';
import { GetPsoServDispSlice_STEP, SetLockHoraSlice } from './Agendamiento/InfoStep';
import { GetHourDispSlice_STEP } from './Agendamiento/InfoStep';
import { GetCfDispSlice_STEP } from './Agendamiento/InfoStep';
// import { SetPsoReservaSlice } from './FormService/InfoStep';

// MENU PARA LOS DISTINTOS CLIENTES
import { GetMenuPsoListPsoSlice, GetMenuPsoListPswPsoSlice, GetMenuPsoListPSwSlice, MenuGetConexionSlice } from './Menu/MenuSlice';
import { GetGalleryImgServSlice, GetImageServSlice, GetPreciosPsoListPswSlice, SetImageServSlice, UpdServPsoSlice } from './Precios/Precios_pso';

//AGENDAMIENTO
import { GetImageTransferSlice, SetImageTransferenciaSlice } from './Agendamiento/Pagos/Transferencia/TransferenciaSlice'; // SET IMAGE TRANSFERENCIA
import { GetDescriptionCfSlice, GetGalleryImagesCfSlice, GetImageProfileCfSlice, SetImagePerfilCfSlice } from './Perfil_cf/Perfil_cf';

//FORMULARIOS DE CONFIGURACION
// import { GetConfigFormSlice, GetConfigPerfilCfSlice, GetMenuConfigPsoSlice, GetMenuPsoConfigSlice } from './FormService/ConfigForm';
// import { GetMenuConfigSlice } from './FormService/ConfigForm';
// import { GetGenInfoFormSlice } from './FormService/GetGenInfoForm';

// Define el tipo RootState para utilizarlo en useSelector
export type  RootState = ReturnType<typeof store.getState>;

// Define el tipo AppDispatch para utilizarlo en useDispatch
export type AppDispatch = typeof store.dispatch;

export type SliceNames =

  | 'auth'
  | 'GetTYC_Cf'

  // perfil pso
  | 'GetImageProfilePso'
  | 'GetDescriptionPso'
  | 'GetGalleryImagesPso'
  
  // perfil cf
  | 'GetImageProfileCf'
  | 'GetDescriptionCf'
  | 'GetGalleryImagesCf'
  | 'SetImageProfileCf'
  
  // OPCIONES DE MENU
  | 'GetMenuPsoListPso'
  | 'GetMenuPsoListPsw'
  | 'GetMenuConexion'
  | 'GetMenuPsoListPswPso'

  // SERVICIOS
  | 'GetPreciosPsoListPsw'
  | 'SetImageServ'
  | 'UpdServPso'
  | 'GetImageServ'
  | 'GetGalleryImgServ'

  // AGENDAMIENTO
  | 'SetImageTransferencia'
  | 'SetLockHora'
  | 'GetImageTransfer'
  
  // | 'AddImageServ'
  // | 'GetPsoImage'
  // | 'DelImage'
  | 'calUser'
  // | 'getInfoHoraReserva'
  // | 'UpdStateHora'
  // | 'DelHoraReserva'
  // | 'profileGral'
  // | 'getPso'
  // | 'GetPsfPsoUser'
  // | 'SetPsfPsoUser'
  // | 'GetPsoPsw'
  // | 'GetPsoLocCalSlice'
  // | 'calProfUser'
  // | 'calServUser'
  // | 'calPswUser'
  // | 'calMatchUser'
  // | 'SetHoraPsw'
  | 'GetStepPso'
  // | 'GET_INFO_STEP'
  // | 'GetPsoLocCalSlice_STEP'
  | 'GetPsoServDispSlice_STEP'
  | 'GetHourDispSlice_STEP'
  | 'Variables'
  | 'GetCfDispSlice_STEP'
  // | 'SetPsoReservaSlice'
  // | 'GetConfigFormSlice'
  // | 'GetMenuConfigSlice'
  // | 'GetMenuConfigPsoSlice'
  // | 'GetConfigPerfilCfSlice'
  // | 'GetMenuConfigPsoSlice'
  // | 'GetMenuPsoConfigSlice'
  // | 'GetGenInfoFormSlice'
  
  ;

const store = configureStore({
  reducer: {

    // autenticacion y registro
    auth: authSlice,
    GetTYC_Cf: GetTYC_CfSlice,

    // Perfil PSO
    GetImageProfilePso: GetImageProfilePsoSlice,
    GetDescriptionPso: GetDescriptionPsoSlice,
    GetGalleryImagesPso: GetGalleryImagesPsoSlice,
    
    // Perfil CF
    GetImageProfileCf: GetImageProfileCfSlice,
    SetImageProfileCf: SetImagePerfilCfSlice,
    GetDescriptionCf: GetDescriptionCfSlice,
    GetGalleryImagesCf: GetGalleryImagesCfSlice,

    // MENU: opciones del menu
    GetMenuPsoListPso: GetMenuPsoListPsoSlice,
    GetMenuPsoListPsw: GetMenuPsoListPSwSlice,
    GetMenuPsoListPswPso: GetMenuPsoListPswPsoSlice,
    GetMenuConexion: MenuGetConexionSlice,

    // PRECIOS 
    GetPreciosPsoListPsw: GetPreciosPsoListPswSlice,
    SetImageServ: SetImageServSlice,
    GetGalleryImgServ: GetGalleryImgServSlice,
    GetImageServ: GetImageServSlice,
    UpdServPso: UpdServPsoSlice,

    // AGENDAMIENTO
    SetImageTransferencia: SetImageTransferenciaSlice, // TRANSFERENCIA : SET IMAGE 
    SetLockHora: SetLockHoraSlice, // TRANSFERENCIA : SET IMAGE 
    GetImageTransfer: GetImageTransferSlice, // TRANSFERENCIA : SET IMAGE 
    
    // imagenes
    // AddImageServ: SetImageSlice, // Agregar imagen a un servicio
    // GetPsoImage: GetImageProfilePSWSlice, // obtener imagenes del perfil de un PSW
    // DelImage: DelImageSlice, // obtener imagenes del perfil de un PSW
    // GetImageAllFormat: GetImageAllFormatSlice, // obtener imagenes del perfil de un PSW

    // calendario menu
    calUser: calUserSlice, // obtener el calendario
    // getInfoHoraReserva: GetInfoHoraReservaSlice, // obtener informacion de una hora reservada
    // UpdStateHora: UpdStateHoraSlice, // Cambiar estado de reserva
    // DelHoraReserva: DelHoraReservaSlice, // Cambiar estado de reserva
    

    // configuracion perfil general
    // profileGral: ProfileGralSlice,
    // getPso: GetPsoUserSlice,

    // configuracion perfil empresa
    // GetPsfPsoUser: GetPsfPsoUserSlice,
    // SetPsfPsoUser: SetPsfPsoUserSlice, // agregar un cliente final a una lista PSO
    // GetPsoPsw: GetPsoPswSlice, // Trae todos los psw de un pso

    // informacion del calendario
    // GetPsoLocCalSlice: GetPsoLocCalSlice,

    // formulario para agregar un evento
    // calProfUser: calProfUserSlice,  // obtener la empresa y su localidad
    // calServUser: calServUserSlice,  // obtener los servicios 
    // calPswUser: calPswUserSlice,  // obtener los trabajadores 
    // calMatchUser: calMatchUserSlice,  // obtener los bloqueo 
    // SetHoraPsw: SetHoraPswSlice,  // agregar una reserva

    // FORMULARIO PARA SERVICIOS
    // GET_INFO_STEP: SLICE_GET_INFO_STEP,
    GetStepPso: GetStepPsoSlice,

    // FORMULARIO DE AGENDAMIENTO/ARRENDAMIENTO
    // GetPsoLocCalSlice_STEP: GetPsoLocCalSlice_STEP, // pso loc sede
    GetPsoServDispSlice_STEP: GetPsoServDispSlice_STEP, // serv
    GetHourDispSlice_STEP: GetHourDispSlice_STEP, // hora
    GetCfDispSlice_STEP: GetCfDispSlice_STEP, // Clientes
    // SetPsoReservaSlice: SetPsoReservaSlice, // Clientes

    Variables: variablesSlice,
    // FORMULARIOS DE CONFIGURACION
    // GetConfigFormSlice: GetConfigFormSlice,
    // GetMenuConfigSlice: GetMenuConfigSlice,
    // GetMenuConfigPsoSlice: GetMenuConfigPsoSlice,
    // GetConfigPerfilCfSlice: GetConfigPerfilCfSlice,
    // GetMenuPsoConfigSlice: GetMenuPsoConfigSlice,
    // Otros reducers aquí, si los tienes


    // obtener informacion
    // GetGenInfoFormSlice: GetGenInfoFormSlice,
  },
  // Otras configuraciones opcionales
});


export const useAppDispatch = () => useDispatch<AppDispatch>();

// Función para seleccionar el estado de un slice específico
export const useAppSelectorBySlice = (sliceName: SliceNames) => {
  return useAppSelector((state: RootState) => state[sliceName]);
};

// Define el tipo de las acciones asíncronas
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const userAppDispatch = () => useDispatch<AppDispatch>;
export const useAppSelector : TypedUseSelectorHook<RootState> = useSelector;
export default store;


