
import { GetGalleryImagesCfTypes, GetGalleryImagesCfState, GetGalleryImagesCfData } from './types';
import { GetDescriptionCfTypes, GetDescriptionCfState, GetDescriptionCfData } from './types';
import { GetImageProfileCfTypes, GetImageProfileCfState, GetImageProfileCfData } from './types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SetImagePerfilCfTypes, SetImagePerfilCfState, SetImagePerfilCfData } from './types';


export const GetImageProfileCfFUNC = createAsyncThunk(
  `auth/${GetImageProfileCfTypes.GET_IMAGE_PROFILE_CF}`,
  async (payload: { id_q: string, GlobalUser: string, GlobalSize: string, }, thunkAPI) => {
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = { "GlobalUser": payload.GlobalUser, "GlobalSize": payload.GlobalSize };
      const json_vi = {};

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };
      // console.log("combinedObject", combinedObject);
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: GetImageProfileCfData[] = await response.json();
        // console.log("GetInfoHoraReserva", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);

const GetImageProfileCfinitialState: GetImageProfileCfState = {
  user: null,
  isLoading: false,
  isError: false,
  isRsp: false,
  error: null,
};

export const GetImageProfileCfSlice = createSlice({
  name: 'calendar',
  initialState: GetImageProfileCfinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        GetImageProfileCfFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.isRsp = false;
          state.error = null;
          // state.user = null;
        }
      )
      .addCase(
        GetImageProfileCfFUNC.fulfilled,
        (state, action: PayloadAction<GetImageProfileCfData[]>) => {
          if (action.payload) {
            state.isLoading = false;
            state.isRsp = true;
            state.user = action.payload;

          }
        }
      )
      .addCase(
        GetImageProfileCfFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
            state.user = null;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
            state.user = null;
          }
        }
      )
      ;
  },
}).reducer;

export const GetDescriptionCfFUNC = createAsyncThunk(
  `auth/${GetDescriptionCfTypes.GET_DESCRIPTION_CF}`,
  async (payload: { id_q: string, GlobalPsw: string, GlobalDate: string, GlobalDateTime: string }, thunkAPI) => {
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = { "GlobalPsw": payload.GlobalPsw, "GlobalDate": payload.GlobalDate, "GlobalDateTime": payload.GlobalDateTime };
      const json_vi = {};

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };
      // console.log("combinedObject", combinedObject);
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: GetDescriptionCfData[] = await response.json();
        // console.log("GetInfoHoraReserva", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);

const GetDescriptionCfinitialState: GetDescriptionCfState = {
  user: null,
  isUser: false,
  isLoading: false,
  isError: false,
  error: null,
};

export const GetDescriptionCfSlice = createSlice({
  name: 'calendar',
  initialState: GetDescriptionCfinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        GetDescriptionCfFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
        }
      )
      .addCase(
        GetDescriptionCfFUNC.fulfilled,
        (state, action: PayloadAction<GetDescriptionCfData[]>) => {
          if (action.payload) {
            state.isLoading = false;
            state.user = action.payload;

          }
        }
      )
      .addCase(
        GetDescriptionCfFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;

export const GetGalleryImagesCfFUNC = createAsyncThunk(
  `auth/${GetGalleryImagesCfTypes.GET_GALLERY_IMAGE_CF}`,
  async (payload: { id_q: string, GlobalUser: string, GlobalSize: string, }, thunkAPI) => {
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = { "GlobalUser": payload.GlobalUser, "GlobalSize": payload.GlobalSize };
      const json_vi = {};

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };
      // console.log("combinedObject", combinedObject);
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      console.log("payload", payload)
      console.log("combinedObject", combinedObject)
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: GetGalleryImagesCfData[] = await response.json();
        // console.log("GetInfoHoraReserva", result);
        return result;

      } catch (jsonError) {
        // console.log("jsonError", jsonError);
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      // console.log("fetchError", fetchError);
      throw new Error(`Error de autenticación: `);
    }
  }
);

const GetGalleryImagesCfinitialState: GetGalleryImagesCfState = {
  user: null,
  isLoading: false,
  isRsp: false,
  isError: false,
  error: null,
};

export const GetGalleryImagesCfSlice = createSlice({
  name: 'calendar',
  initialState: GetGalleryImagesCfinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        GetGalleryImagesCfFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.isRsp = false;
          state.error = null;
        }
      )
      .addCase(
        GetGalleryImagesCfFUNC.fulfilled,
        (state, action: PayloadAction<GetGalleryImagesCfData[]>) => {
          if (action.payload) {
            state.isLoading = false;
            state.isRsp = true;
            state.user = action.payload;

          }
        }
      )
      .addCase(
        GetGalleryImagesCfFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;


// set imagen de perfil
export const SetImagePerfilCfFUNC = createAsyncThunk(
  `auth/${SetImagePerfilCfTypes.SET_IMAGE_PERFIL}`,
  async (payload: {
    cod_entidad: string;
    rol_entidad: string;
    visible: string;
    fecha: string;
    cod_pso: string;
    enable_blob: string;
    var_img_sd: string;
    var_img_hd: string;
  }, thunkAPI) => {
    try {

      
      const json_table = { "master_table": "asp_img_perfil_rol", "master_db_location_table": "asp_img_perfil_rol_location" };
      const json_location: any[] = [{ "field_vector": "1", "field_name": "rol_entidad" }, { "field_vector": "2", "field_name": "img_db_size" }];
      const json_v1 = {
        "cod_entidad": payload.cod_entidad,
        "fecha": payload.fecha,
        "rol_entidad": payload.rol_entidad,
        "cod_pso": payload.cod_pso,
        "enable_blob": payload.enable_blob,
        "img_id": "@SYS_IMG_ID_SERIAL",
        "img_mask": "@SYS_IMG_ID_MASK"
      };
      const json_v2 = [
        {
          "img_db_size": "200",
          "img_data": payload.var_img_sd,
          "img_mask": "S"
        },
        {
          "img_db_size": "1600",
          "img_data": payload.var_img_hd,
          "img_mask": "H"
        }];
      const json_v3 =
      {
        "img_date": payload.fecha,
        "img_text": "imagen de perfil",
        "img_tag": "tags"
      };

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_table,
        json_location,
        json_v1,
        json_v2,
        json_v3,
      };
      // console.log("combinedObject", combinedObject);
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia_set.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: SetImagePerfilCfData = await response.json();
        // console.log("SetImagePerfilCfData", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);

const SetImagePerfilCfinitialState: SetImagePerfilCfState = {
  rsp: null,
  isRsp: false,
  isLoading: false,
  isError: false,
  error: null,
};

export const SetImagePerfilCfSlice = createSlice({
  name: 'SetImagePerfilCf',
  initialState: SetImagePerfilCfinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        SetImagePerfilCfFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
          state.isRsp = false;
        }
      )
      .addCase(
        SetImagePerfilCfFUNC.fulfilled,
        (state, action: PayloadAction<SetImagePerfilCfData>) => {
          if (action.payload) {
            state.isLoading = false;
            state.rsp = action.payload;
            state.isRsp = true;

          }
        }
      )
      .addCase(
        SetImagePerfilCfFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;