// authSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetStepPsoTypes, GetStepPsoState, GetStepPsoData } from './types';

// la funcion que pregunta a la API
export const GetStepPsoFUNC = createAsyncThunk(
  `auth/${GetStepPsoTypes.GET_STEP_PSO}`,
  async (payload: { id_q: string, GlobalProf: string, GlobalEsp: string }, thunkAPI) => {
    // { id_q: 'GET_STEP_PROF_ESP', GlobalProf: 'LASHITA', GlobalEsp: 'GRAL' }
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = { "GlobalProf": payload.GlobalProf, "GlobalEsp": payload.GlobalEsp};
      const json_vi = {};

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };
      // console.log("combinedObject GetStepPsoFUNC", combinedObject);
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: GetStepPsoData[] = await response.json();
        // console.log("GetStepPsoData result", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);


const GetStepPsoinitialState: GetStepPsoState = {
  StepPso: null,
  isLoading: false,
  isError: false,
  error: null,
};

// maneja los estados de las peticiones a la API
export const GetStepPsoSlice = createSlice({
  name: 'calendar',
  initialState: GetStepPsoinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        GetStepPsoFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
        }
      )
      .addCase(
        GetStepPsoFUNC.fulfilled,
        (state, action: PayloadAction<GetStepPsoData[]>) => {
          if (action.payload) {
            state.isLoading = false;
            state.StepPso = action.payload;

          }
        }
      )
      .addCase(
        GetStepPsoFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;


