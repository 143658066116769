// authSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CalUserActionTypes, CalState } from './types';
import { GetInfoHoraReservaTypes, GetInfoHoraReservaState, GetInfoHoraReservaData } from './types';
import { UpdStateHoraTypes, UpdStateHoraState, UpdStateHoraData } from './types';
import { DelHoraReservaTypes, DelHoraReservaState, DelHoraReservaData } from './types';
import { AddImageServTypes, AddImageServState, AddImageServData } from './types';

interface CalendarData {
  "0": string[]; // Cadena JSON con la propiedad "hora_ini" y "hora_fin"
  "@JSON_FINAL": string[]; // Cadena JSON con la misma estructura que la propiedad "0"
}


// PETICIONES AL SERVIDOR

// call GWA_SQL_EXEC_2( '{"sql_id":"GET_CALENDARIO_V2"}', '[]', 
// '{"GlobalDate":"2024-01-18", "GlobalMode":"W", "GlobalRol":"PSW", "GlobalUser":"19481852-1", 
// "GlobalPSO":"IBLOOM", "GlobalType":"A"}', '{}' );

export const getCalUser = createAsyncThunk(
  `auth/${CalUserActionTypes.GET_CAL_USER}`,
  async (payload: {
    id_q: string, currentDate: string, GlobalMode: string, GlobalRol: string,
    GlobalUser: string, GlobalPSO: string | null, GlobalType: string | null,
    GlobalCF: string | null, GlobalPsw: string | null, GlobalLoc: string | null, GlobalSede: string | null
  }, thunkAPI) => {
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = {
        "GlobalDate": payload.currentDate, "GlobalMode": payload.GlobalMode, "GlobalRol": payload.GlobalRol,
        "GlobalUser": payload.GlobalUser, "GlobalPso": payload.GlobalPSO, "GlobalType": payload.GlobalType, "GlobalCf": payload.GlobalCF, 
        "GlobalPsw": payload.GlobalPsw, "GlobalLoc": payload.GlobalLoc, "GlobalSede": payload.GlobalSede
      };
      const json_vi = {};
      // console.log("getCalUser", 'ini');
      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };

      console.log("getCalUser", 'ini', combinedObject);

      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: CalendarData = await response.json();
        console.log("CalendarData", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);

const initialState: CalState = {
  user: null,
  isLoading: false,
  isError: false,
  error: null,
  token: null
};

export const calUserSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getCalUser.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
        }
      )
      .addCase(
        getCalUser.fulfilled,
        (state, action: PayloadAction<CalendarData>) => {
          if (action.payload) {
            state.isLoading = false;
            state.user = action.payload;

          }
        }
      )
      .addCase(
        getCalUser.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;

export const GetInfoHoraReservaFUNC = createAsyncThunk(
  `auth/${GetInfoHoraReservaTypes.GET_GET_INFO_HORA_RESERVA}`,
  async (payload: { id_q: string, GlobalPsw: string, GlobalDate: string, GlobalDateTime: string }, thunkAPI) => {
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = { "GlobalPsw": payload.GlobalPsw, "GlobalDate": payload.GlobalDate, "GlobalDateTime": payload.GlobalDateTime };
      const json_vi = {};

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };
      // console.log("combinedObject", combinedObject);
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: GetInfoHoraReservaData[] = await response.json();
        // console.log("GetInfoHoraReserva", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);

const GetInfoHoraReservaStateinitialState: GetInfoHoraReservaState = {
  infoReserva: null,
  isLoading: false,
  isError: false,
  error: null,
};

export const GetInfoHoraReservaSlice = createSlice({
  name: 'calendar',
  initialState: GetInfoHoraReservaStateinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        GetInfoHoraReservaFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
        }
      )
      .addCase(
        GetInfoHoraReservaFUNC.fulfilled,
        (state, action: PayloadAction<GetInfoHoraReservaData[]>) => {
          if (action.payload) {
            state.isLoading = false;
            state.infoReserva = action.payload;

          }
        }
      )
      .addCase(
        GetInfoHoraReservaFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;

export const UpdStateHoraFUNC = createAsyncThunk(
  `auth/${UpdStateHoraTypes.UPD_STATE_HORA}`,
  async (payload: { id_q: string, GlobalPsw: string, GlobalDate: string, GlobalDateTime: string, GlobalState: string, GlobalCliente: string }, thunkAPI) => {
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = { "GlobalPsw": payload.GlobalPsw, "GlobalDate": payload.GlobalDate, "GlobalDateTime": payload.GlobalDateTime, "GlobalState": payload.GlobalState, "GlobalCliente": payload.GlobalCliente };
      const json_vi = {};

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };

      ////console.log(combinedObject)
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: UpdStateHoraData[] = await response.json();
        // console.log("UpdStateHora", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);

const UpdStateHoraStateinitialState: UpdStateHoraState = {
  updStateReserva: null,
  isLoading: false,
  isError: false,
  error: null,
};

export const UpdStateHoraSlice = createSlice({
  name: 'calendar',
  initialState: UpdStateHoraStateinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        UpdStateHoraFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
        }
      )
      .addCase(
        UpdStateHoraFUNC.fulfilled,
        (state, action: PayloadAction<UpdStateHoraData[]>) => {
          if (action.payload) {
            state.isLoading = false;
            state.updStateReserva = action.payload;

          }
        }
      )
      .addCase(
        UpdStateHoraFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;

export const DelHoraReservaFUNC = createAsyncThunk(
  `auth/${DelHoraReservaTypes.DEL_HORA_RESERVA}`,
  async (payload: { id_q: string, GlobalPsw: string, GlobalDate: string, GlobalDateTime: string, GlobalCliente: string }, thunkAPI) => {
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = { "GlobalPsw": payload.GlobalPsw, "GlobalDate": payload.GlobalDate, "GlobalDateTime": payload.GlobalDateTime, "GlobalCliente": payload.GlobalCliente };
      const json_vi = {};

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };

      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: DelHoraReservaData[] = await response.json();
        ////console.log("DelHoraReserva", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);

const DelHoraReservaStateinitialState: DelHoraReservaState = {
  delHoraReserva: null,
  isLoading: false,
  isError: false,
  isFinish: false,
  error: null,
};

export const DelHoraReservaSlice = createSlice({
  name: 'calendar',
  initialState: DelHoraReservaStateinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        DelHoraReservaFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
          state.isFinish = false;
        }
      )
      .addCase(
        DelHoraReservaFUNC.fulfilled,
        (state, action: PayloadAction<DelHoraReservaData[]>) => {
          if (action.payload) {
            state.isLoading = false;
            state.isFinish = true;
            state.delHoraReserva = action.payload;
          }
        }
      )
      .addCase(
        DelHoraReservaFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;


export const AddImageServFUNC = createAsyncThunk(
  `auth/${AddImageServTypes.ADD_IMAGE_SERV}`,
  async (payload: { id_q: string, GlobalDate: string, GlobalPso: string, GlobalPsw: string, GlobalCliente: string, GlobalLoc: string, GlobalServ: string, ImageDesc: string, ImageType: string, ImageBin: string | null }, thunkAPI) => {
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = { "GlobalDate": payload.GlobalDate, "GlobalPso": payload.GlobalPso, "GlobalPsw": payload.GlobalPsw, "GlobalCliente": payload.GlobalCliente, "GlobalLoc": payload.GlobalLoc, "GlobalServ": payload.GlobalServ, "ImageDesc": payload.ImageDesc, "ImageType": payload.ImageType, "ImageBin": payload.ImageBin };
      const json_vi = {};

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };

      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: AddImageServData = await response.json();
        ////console.log("AddImageServ", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);

const AddImageServStateinitialState: AddImageServState = {
  AddImageServ: null,
  isLoading: false,
  isError: false,
  isFinish: false,
  error: null,
};

export const AddImageServSlice = createSlice({
  name: 'calendar',
  initialState: AddImageServStateinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        AddImageServFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
          state.isFinish = false;
        }
      )
      .addCase(
        AddImageServFUNC.fulfilled,
        (state, action: PayloadAction<AddImageServData>) => {
          if (action.payload) {
            state.isLoading = false;
            state.isFinish = true;
            state.AddImageServ = action.payload;
          }
        }
      )
      .addCase(
        AddImageServFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;

