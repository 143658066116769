export enum AuthActionTypes {
    SIGN_IN = 'SIGN_IN',
    SIGN_OUT = 'SIGN_OUT',
    SIGN_UP = 'SIGN_UP',
    RESTORE_TOKEN = 'RESTORE_TOKEN',
}

export type AuthUser = {
    token: string;
    nemo_persona: string;
    nombre_persona: string;
    apat_persona: string;
    amat_persona: string;
    fono_persona: string;
    dir_text: string;
    dir_num: string;
    mail_persona: string;
    rut_persona: string;
    dv_persona: string;
    cod_persona:string
    user_type: string;
    cod_pso: string;
    cod_loc: string;
    cod_sede: string;
    RSP: string;

}

 export var AuthUserIni = {
    token: 'string',
    nemo_persona: 'string',
    nombre_persona: 'string',
    apat_persona: 'string',
    amat_persona: 'string',
    fono_persona: 'string',
    dir_text: 'string',
    dir_num: 'string',
    mail_persona: 'string',
    rut_persona: 'string',
    dv_persona: 'string',
    cod_persona:'string',
    user_type: 'string',
    cod_pso: 'string',
    cod_loc: 'string',
    cod_sede: 'string',
    RSP: 'string'

}

export type AuthState = {
    isAuthenticated: boolean;
    user: AuthUser ;
    isLoading: boolean;
    isQuest: boolean;
    isError: boolean;
    error: string | null | unknown;


};

export type RootState = {
    auth: AuthState;
}


// --------------------------------------------------
export type GetTYC_CfData = {
    fecha: string;
    text_tyc : string;
  
  }
  
  
  export enum GetTYC_CfTypes {
    GET_TYC_CF = 'GET_TYC_CF',
  }
  
  export type GetTYC_CfState = {
    rsp: GetTYC_CfData | null;
    isLoading: boolean;
    isRsp: boolean;
    isError: boolean;
    error: string | null | unknown;
  };
  