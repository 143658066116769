
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MenuGetConexionTypes, MenuGetConexionState, MenuGetConexionData } from './MenuTypes';
import { GetMenuPsoListPsoTypes, GetMenuPsoListPsoState, GetMenuPsoListPsoData } from './MenuTypes';
import { GetMenuPsoListPSwTypes, GetMenuPsoListPSwState, GetMenuPsoListPSwData } from './MenuTypes';
import { GetMenuPsoListPswPsoTypes, GetMenuPsoListPswPsoState, GetMenuPsoListPswPsoData } from './MenuTypes';

// Trae las conexiones que tienes con las empresas // GET_MENUPSO_LIST_CNX
export const MenuGetConexionFUNC = createAsyncThunk(
    `auth/${MenuGetConexionTypes.GET_MENUPSO_LIST_CONX}`,
    async (payload: { id_q: string, GlobalUser: string }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
            const json_vc: any[] = [];
            const json_ve = { "GlobalUser": payload.GlobalUser };
            const json_vi = {};

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_sql,
                json_vc,
                json_ve,
                json_vi
            };
            // console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: MenuGetConexionData[] = await response.json();
                // console.log("MenuGetConexionData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const MenuGetConexioninitialState: MenuGetConexionState = {
    rsp: null,
    isLoading: false,
    isError: false,
    isRsp: false,
    error: null,
};

export const MenuGetConexionSlice = createSlice({
    name: 'calendar',
    initialState: MenuGetConexioninitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                MenuGetConexionFUNC.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                    state.isRsp = false;
                }
            )
            .addCase(
                MenuGetConexionFUNC.fulfilled,
                (state, action: PayloadAction<MenuGetConexionData[]>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;
                        state.isRsp = true;

                    }
                }
            )
            .addCase(
                MenuGetConexionFUNC.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;

// trae las empresas asociadas al codigo de persona GET_MENUPSO_LIST_PSO
export const GetMenuPsoListPsoFUNC = createAsyncThunk(
    `auth/${GetMenuPsoListPsoTypes.GET_MENUPSO_LIST_PSO}`,
    async (payload: { id_q: string, GlobalUser: string }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
            const json_vc: any[] = [];
            const json_ve = { "GlobalUser": payload.GlobalUser };
            const json_vi = {};

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_sql,
                json_vc,
                json_ve,
                json_vi
            };
            console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: GetMenuPsoListPsoData[] = await response.json();
                // console.log("GetMenuPsoListPsoData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const GetMenuPsoListPsoinitialState: GetMenuPsoListPsoState = {
    rsp: null,
    isLoading: false,
    isError: false,
    error: null,
};

export const GetMenuPsoListPsoSlice = createSlice({
    name: 'calendar',
    initialState: GetMenuPsoListPsoinitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                GetMenuPsoListPsoFUNC.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                }
            )
            .addCase(
                GetMenuPsoListPsoFUNC.fulfilled,
                (state, action: PayloadAction<GetMenuPsoListPsoData[]>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;

                    }
                }
            )
            .addCase(
                GetMenuPsoListPsoFUNC.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;

// Trae las opciones donde el usuario es trabajador // GET_MENUPSO_LIST_PSW
export const GetMenuPsoListPSwFUNC = createAsyncThunk(
    `auth/${GetMenuPsoListPSwTypes.GET_MENUPSO_LIST_PSW}`,
    async (payload: { id_q: string, GlobalUser: string }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
            const json_vc: any[] = [];
            const json_ve = { "GlobalUser": payload.GlobalUser };
            const json_vi = {};

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_sql,
                json_vc,
                json_ve,
                json_vi
            };
            // console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: GetMenuPsoListPSwData[] = await response.json();
                console.log("GetMenuPsoListPSwData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const GetMenuPsoListPSwinitialState: GetMenuPsoListPSwState = {
    rsp: null,
    isLoading: false,
    isError: false,
    error: null,
};

export const GetMenuPsoListPSwSlice = createSlice({
    name: 'calendar',
    initialState: GetMenuPsoListPSwinitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                GetMenuPsoListPSwFUNC.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                }
            )
            .addCase(
                GetMenuPsoListPSwFUNC.fulfilled,
                (state, action: PayloadAction<GetMenuPsoListPSwData[]>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;

                    }
                }
            )
            .addCase(
                GetMenuPsoListPSwFUNC.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;

// --------------------------------------------------
// Trae los psw de una pso
export const GetMenuPsoListPswPsoFUNC = createAsyncThunk(
    `auth/${GetMenuPsoListPswPsoTypes.GET_MENUPSO_LIST_PSW_PSO}`,
    async (payload: { id_q: string, GlobalPso: string }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
            const json_vc: any[] = [];
            const json_ve = { "GlobalPso": payload.GlobalPso };
            const json_vi = {};

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_sql,
                json_vc,
                json_ve,
                json_vi
            };
            // console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: GetMenuPsoListPswPsoData[] = await response.json();
                // console.log("GetMenuPsoListPswPsoData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const GetMenuPsoListPswPsoinitialState: GetMenuPsoListPswPsoState = {
    rsp: null,    
    isRsp: false,
    isLoading: false,
    isError: false,
    error: null,
};

export const GetMenuPsoListPswPsoSlice = createSlice({
    name: 'calendar',
    initialState: GetMenuPsoListPswPsoinitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                GetMenuPsoListPswPsoFUNC.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.isRsp = false;
                    state.error = null;
                }
            )
            .addCase(
                GetMenuPsoListPswPsoFUNC.fulfilled,
                (state, action: PayloadAction<GetMenuPsoListPswPsoData[]>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.isRsp = true;
                        state.rsp = action.payload;

                    }
                }
            )
            .addCase(
                GetMenuPsoListPswPsoFUNC.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;