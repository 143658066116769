import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import GoogleAuth from './components/GoogleAuth';
import RegistrationForm from './views/Registro/RegistrationForm';
import MsgClienteconRegistroForm from './views/Registro/MsgClienteconRegistroForm';
import MsgBienvenidaClienteForm from './views/Registro/MsgBienvenidaClienteForm';
import { registerUser } from './api/user';
import { Container, Typography, Box, Button } from '@mui/material';
import { Provider } from 'react-redux';
import store, { AppDispatch, useAppSelectorBySlice } from './model/store';
import { useDispatch } from 'react-redux';
import { functionMap } from '../src/model/storeFunc';

const App: React.FC = () => {
    const [user, setUser] = useState<any>(null);
    const [isRegistered, setIsRegistered] = useState(false);
    const [pso, setPso] = useState<string | null>(null);
    const [environment, setEnvironment] = useState<'local' | 'web'>('web');
    const [apiResponse, setApiResponse] = useState<any>(null);
    const [registrationCompleted, setRegistrationCompleted] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const auth_check: any = useAppSelectorBySlice('auth');

    const getUserType = () => {
        if (!auth_check.user) {
            console.log("(App, getUserType) auth_check.user is not defined");
            return null;
        }

        const userType = auth_check.user.user_type;

        if (userType === 'CF') {
            console.log("(App, getUserType CF) auth_check.user.user_type cliente");
            return 'cliente';
        }
        if (userType === 'PSW') {
            console.log("(App, getUserType CF) auth_check.user.user_type trabajador");
            return 'trabajador';
        }
        if (userType === 'PSO') {
            console.log("(App, getUserType CF) auth_check.user.user_type empresa");
            return 'empresa';
        }else{
            console.log("(App, getUserType registro) auth_check.user.user_type empresa");
            return 'registro';
        }

        console.log("(App, getUserType ) auth_check.user.user_type is <> CF, PSW, PSO: ", userType);
        return 'unknown';
    };

    useEffect(() => {
        console.log("(App, useEffect) App component mounted");
        const urlParams = new URLSearchParams(window.location.search);
        const psoParam = urlParams.get('pso');
        if (psoParam) {
            setPso(psoParam);
            console.log(`(App, useEffect) PSO parameter set: ${psoParam}`);
        }

        if (window.location.hostname === 'localhost') {
            setEnvironment('local');
        } else {
            setEnvironment('web');
        }
        console.log(`(App, useEffect) Environment set to: ${environment}`);
        console.log(`(App, useEffect) URL ingresada : ${window.location.pathname}`);
    }, []);

    useEffect(() => {
        console.log("(App, useEffectAuth[dispatch, auth_check]) check pre:", auth_check);
        if (!auth_check.isLoading && auth_check.isQuest) {
            console.log('(App, useEffectAuth[dispatch, auth_check]) check completed:', auth_check.user);
            setApiResponse(auth_check.user);
            setIsRegistered(auth_check.user?.RSP === "1");

            if (auth_check.user?.RSP === "1") {
                setUser(auth_check.user);
                
            }
            
        }
    }, [dispatch, auth_check]);

    const handleLoginSuccess = useCallback(async (user: any) => {
        console.log(`(App, handleLoginSuccess) Handling login success for user: ${JSON.stringify(user)}, Mode: ${environment}`);
        setUser(user);
        const GlobalUser = user.email.split('@')[0];
        console.log(`(App, handleLoginSuccess) alert user --: `, user);
        alert(`Email seleccionado: ${user.email}\nGlobalUser: ${GlobalUser}`);
        console.log('(App, handleLoginSuccess) Authenticated user ++: ', user);
        try {
            console.log(`(App, handleLoginSuccess) Checking user registration -- for: ${GlobalUser}, Mode: ${environment}`);
            functionMap['checkUserRegistration']({
                GlobalUser: GlobalUser,
            }, dispatch);
        } catch (error) {
            console.error('(App, handleLoginSuccess) Error checking user registration:', error);
            setApiResponse(error);
        }
    }, [dispatch, environment]);

    const handleLocalLogin = useCallback(async () => {
        console.log('(App, handleLocalLogin) Handling local login');
        const localUser = { email: 'draignaciaprovostegreve@gmail.com' };
        console.log(`(App, handleLocalLogin) Local User: ${JSON.stringify(localUser)}`);
        await handleLoginSuccess(localUser);
    }, [handleLoginSuccess]);

    const handleRegistrationSubmit = useCallback(async (values: any, base64ImageSmall : string, base64ImageLarge : string) => {
        console.log(`(App, handleRegistrationSubmit) Submitting registration form with values: ${JSON.stringify(values)}, Mode: ${environment}`);
        try {
            const response = await registerUser({ ...values, pso });
            console.log(`(App, handleRegistrationSubmit) Registration response: ${JSON.stringify(response)}, Mode: ${environment}`);
            setApiResponse(response);
            setIsRegistered(response.RSP === "1");

            console.log('listo', response)
            if (response.status === "success") {
                console.log('listo 1')
                setRegistrationCompleted(true);
                onImageSave(base64ImageSmall, base64ImageLarge)
            }
        } catch (error) {
            console.error('(App, handleRegistrationSubmit) Error registering user:', error);
            setApiResponse(error);
        }
    }, [pso, environment]);

      const onImageSave = (base64ImageSmall: string, base64ImageLarge: string) => {
        const actionDBsetImgServ = 'setImageProfileCf';
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses son de 0 a 11
        const day = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const initialGlobalUser = user.email ? user.email.split('@')[0] : '';
        const paramsSetImgPerfil = {
          cod_entidad: initialGlobalUser,
          rol_entidad: 'CF',
          visible: "0",
          fecha: formattedDate,
          cod_pso: "",
          enable_blob: "1",
          var_img_sd: base64ImageSmall,
          var_img_hd: base64ImageLarge
        };
    
        console.log("paramsSetImgPerfil", paramsSetImgPerfil)
        functionMap[actionDBsetImgServ](paramsSetImgPerfil, dispatch);
    
      }

    if (!pso) {
        console.error('(App) Error: Missing `pso` parameter in URL');
        return (
            <Container maxWidth="md">
                <Box textAlign="center" my={4}>
                    <Typography variant="h3" component="h1" gutterBottom>
                        Error
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Falta el parámetro `pso` en la URL.
                    </Typography>
                </Box>
            </Container>
        );
    }

    const userType = getUserType();

    // Lógica para manejar la ruta /registro
    const handleRegistrationRoute = () => {
        console.log(`(App, handleRegistrationRoute) Entering /registro Route, Mode: ${environment}, User: ${JSON.stringify(user)}`);
        if (environment === 'local') {
            console.log(`(App, handleRegistrationRoute) modo local`);
            return (
                <div>
                    {/* Mostrar botón para iniciar sesión en modo local */}
                    {!user && !registrationCompleted && (
                        <Button variant="contained" color="primary" onClick={handleLocalLogin}>
                            Iniciar sesión (Simulación)
                        </Button>
                    )}
                    {/* Mostrar mensaje de registro completado si es el caso */}
                    {registrationCompleted && (
                        <MsgBienvenidaClienteForm pso={pso} />
                    )}
                    {/* Luego mostrar el registro o mensaje según el estado */}
                    {user && !registrationCompleted && (
                        isRegistered ? (
                            <MsgClienteconRegistroForm pso={pso} />
                        ) : (
                            <RegistrationForm 
                                email={user?.email} 
                                pso={pso} 
                                onSubmit={handleRegistrationSubmit}
                            />
                        )
                    )}
                </div>
            );
        } else {
            console.log(`(App, handleRegistrationRoute) Entering /registro Route in Web Mode, User: ${JSON.stringify(user)}`);
            return (
                // <GoogleAuth onSuccess={handleLoginSuccess} />
                <RegistrationForm 
                email={user?.email} 
                pso={pso} 
                onSubmit={handleRegistrationSubmit}
            />

            );
        }
    };

    return (
        <Provider store={store}>
            <Router basename={environment === 'web' ? "/" : "/"}>
                <Container maxWidth="lg" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                !auth_check.isQuest ? (
                                    environment === 'local' ? (
                                        <div>
                                            {!user && !registrationCompleted && (
                                                <Button variant="contained" color="primary" onClick={handleLocalLogin}>
                                                    Iniciar sesión (Simulación)
                                                </Button>
                                            )}
                                            {user && userType && !registrationCompleted && <Navigate to={`/${userType}`} />}
                                        </div>
                                    ) : (
                                        <GoogleAuth onSuccess={handleLoginSuccess} />
                                    )
                                ) : (
                                    userType && <Navigate to={`/${userType}`} />
                                )
                            }
                        />
                        <Route
                            path="/registro"
                            element={handleRegistrationRoute()}
                        />
                        <Route
                            path="/home"
                            element={handleRegistrationRoute()}
                        />
                        <Route
                            path="/bienvenida"
                            element={<MsgBienvenidaClienteForm pso={pso} />}
                        />
                    </Routes>
                </Container>
            </Router>
        </Provider>
    );
};

export default App;
