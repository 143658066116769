


import { SetImageTransferenciaTypes, SetImageTransferenciaState, SetImageTransferenciaData } from './types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetImageTransferTypes, GetImageTransferState, GetImageTransferData } from './types';

// set imagen de transferencia
export const SetImageTransferenciaFUNC = createAsyncThunk(
    `auth/${SetImageTransferenciaTypes.SET_IMAGE_TRANSFERENCIA}`,
    async (payload: {
        cod_pso: string;
        cod_psw: string;
        cod_cf: string;
        var_date: string;
        var_loc: string;
        var_sede: string;
        var_serv: string;
        var_img_desc: string;
        var_img_tag: string;
        var_img_sd: string;
        var_img_hd: string;
    }, thunkAPI) => {
        try {
            const json_table = { "master_table": "asp_img_transferencias", "master_db_location_table": "asp_pso_img_transfer_location" };
            const json_location: any[] = [{ "field_vector": "1", "field_name": "cod_pso" }, { "field_vector": "2", "field_name": "img_db_size" }];
            const json_v1 = {
                "fecha": payload.var_date,
                "cod_cf": payload.cod_cf,
                "cod_psw": payload.cod_psw,
                "cod_pso": payload.cod_pso,
                "cod_loc_servicio": payload.var_loc,
                "cod_sede": payload.var_sede,
                "cod_servicio": payload.var_serv,
                "enable_blob": "0",
                "img_id": "@SYS_IMG_ID_SERIAL",
                "img_mask": "@SYS_IMG_ID_MASK"
            };
            const json_v2 = [
                {
                    "img_db_size": "200",
                    "img_data": payload.var_img_sd,
                    "img_mask": "S"
                },
                {
                    "img_db_size": "1600",
                    "img_data": payload.var_img_hd,
                    "img_mask": "H"
                }];
            const json_v3 =
            {
                "img_date": payload.var_date,
                "img_text": payload.var_img_desc,
                "img_tag": payload.var_img_tag
            };

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_table,
                json_location,
                json_v1,
                json_v2,
                json_v3,
            };
            // console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia_set.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: SetImageTransferenciaData = await response.json();
                // console.log("SetImageTransferenciaData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const SetImageTransferenciainitialState: SetImageTransferenciaState = {
    rsp: null,
    isRsp: false,
    isLoading: false,
    isError: false,
    error: null,
};

export const SetImageTransferenciaSlice = createSlice({
    name: 'SetImageTransferencia',
    initialState: SetImageTransferenciainitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                SetImageTransferenciaFUNC.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                    state.isRsp = false;
                }
            )
            .addCase(
                SetImageTransferenciaFUNC.fulfilled,
                (state, action: PayloadAction<SetImageTransferenciaData>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;
                        state.isRsp = true;

                    }
                }
            )
            .addCase(
                SetImageTransferenciaFUNC.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;


// get imagen de transferencia
export const GetImageTransferFUNC = createAsyncThunk(
    `GetImageTransfer/${GetImageTransferTypes.GET_IMAGE_TRANSFER}`,
    async (payload: {
      sql_id: string; 
      globalPSO: string; 
      globalPSW: string; 
      globalCF: string; 
      globalServ: string; 
      var_size: string
      var_date: string; 
    }, thunkAPI) => {
      const json_sql = { "sql_id": payload.sql_id };
      const json_vc: any[] = [];
      const json_ve = {
        "GlobalPSO": payload.globalPSO, 
        "GlobalPSW": payload.globalPSW, 
        "GlobalCF": payload.globalCF, 
        "GlobalServ": payload.globalServ, 
        "GlobalSize": payload.var_size,
        "GlobalDate": payload.var_date,  
      };
      const json_vi = {};
  
      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };
  
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
        const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: var_send,
        });
  
        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.status}`);
        }
  
        try {
          const result: GetImageTransferData[] = await response.json();
          return result;
  
        } catch (jsonError) {
          throw new Error(`Error al procesar la respuesta JSON: `+ jsonError);
        }
    }
  );
  
  const GetImageTransferInitialState: GetImageTransferState = {
    user:  null,
    isProfileGralenticated: false,
    isRsp: false,
    isLoading: false,
    isError: false,
    error: null,
  };
  
  export const GetImageTransferSlice = createSlice({
    name: 'GetImage',
    initialState: GetImageTransferInitialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(
            GetImageTransferFUNC.pending,
          (state) => {
            state.isLoading = true;
            state.isRsp = false;
          }
        )
        .addCase(
            GetImageTransferFUNC.rejected,
          (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isRsp = true;
            state.error = action.payload;
          }
        )
        .addCase(
            GetImageTransferFUNC.fulfilled,
          (state, action: PayloadAction<GetImageTransferData[]>) => {
            if (action.payload) {
              state.user = action.payload;
              state.isLoading = false;
              state.isProfileGralenticated = true;
  
  
            }
          }
        )
    },
  }).reducer;