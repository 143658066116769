
import { GetGalleryImagesPsoTypes, GetGalleryImagesPsoState, GetGalleryImagesPsoData } from './types';
import { GetDescriptionPsoTypes, GetDescriptionPsoState, GetDescriptionPsoData } from './types';
import { GetImageProfilePsoTypes, GetImageProfilePsoState, GetImageProfilePsoData } from './types';
import { SetImagePerfilPsoTypes, SetImagePerfilPsoState, SetImagePerfilPsoData } from './types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const GetImageProfilePsoFUNC = createAsyncThunk(
  `auth/${GetImageProfilePsoTypes.GET_IMAGE_PERFIL_PSO}`,
  async (payload: {
    id_q: string, GlobalUser: string, GlobalSize: string, 
  }, thunkAPI) => {
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = { "GlobalUser": payload.GlobalUser, "GlobalSize": payload.GlobalSize };
      const json_vi = {};

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: GetImageProfilePsoData[] = await response.json();
        console.log("GetImageProfilePsoData", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);

const GetImageProfilePsoInitialState: GetImageProfilePsoState = {
  rsp: null,
  isRsp: false,
  isLoading: false,
  isError: false,
  error: null,
};

export const GetImageProfilePsoSlice = createSlice({
  name: 'GetImageProfilePso',
  initialState: GetImageProfilePsoInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        GetImageProfilePsoFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
          state.isRsp = false;
        }
      )
      .addCase(
        GetImageProfilePsoFUNC.fulfilled,
        (state, action: PayloadAction<GetImageProfilePsoData[]>) => {
          if (action.payload) {
            state.isLoading = false;
            state.rsp = action.payload;
            state.isRsp = true;

          }
        }
      )
      .addCase(
        GetImageProfilePsoFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;

export const GetDescriptionPsoFUNC = createAsyncThunk(
  `auth/${GetDescriptionPsoTypes.GET_DESCRIPTION_PSO}`,
  async (payload: { id_q: string, GlobalPsw: string, GlobalDate: string, GlobalDateTime: string }, thunkAPI) => {
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = { "GlobalPsw": payload.GlobalPsw, "GlobalDate": payload.GlobalDate, "GlobalDateTime": payload.GlobalDateTime };
      const json_vi = {};

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };
      // console.log("combinedObject", combinedObject);
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: GetDescriptionPsoData[] = await response.json();
        // console.log("GetInfoHoraReserva", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);

const GetDescriptionPsoinitialState: GetDescriptionPsoState = {
  user: null,
  isUser: false,
  isLoading: false,
  isError: false,
  error: null,
};

export const GetDescriptionPsoSlice = createSlice({
  name: 'calendar',
  initialState: GetDescriptionPsoinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        GetDescriptionPsoFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
        }
      )
      .addCase(
        GetDescriptionPsoFUNC.fulfilled,
        (state, action: PayloadAction<GetDescriptionPsoData[]>) => {
          if (action.payload) {
            state.isLoading = false;
            state.user = action.payload;

          }
        }
      )
      .addCase(
        GetDescriptionPsoFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;

export const GetGalleryImagesPsoFUNC = createAsyncThunk(
  `auth/${GetGalleryImagesPsoTypes.GET_GALLERY_IMAGE_PSO}`,
  async (payload: { id_q: string, GlobalPso: string, GlobalSize: string, }, thunkAPI) => {
    console.log("payload", payload)
    console.log("payload", payload.id_q)
    try {
      const json_sql = { "sql_id": payload.id_q };
      const json_vc: any[] = [];
      const json_ve = { "GlobalPSO": payload.GlobalPso, "GlobalSize": payload.GlobalSize };
      const json_vi = {};

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_sql,
        json_vc,
        json_ve,
        json_vi
      };
      // console.log("combinedObject", combinedObject);
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: GetGalleryImagesPsoData[] = await response.json();
        // console.log("GetInfoHoraReserva", result);
        return result;

      } catch (jsonError) {
        // console.log("jsonError", jsonError);
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      // console.log("fetchError", fetchError);
      throw new Error(`Error de autenticación: `);
    }
  }
);

const GetGalleryImagesPsoinitialState: GetGalleryImagesPsoState = {
  user: null,
  isLoading: false,
  isError: false,
  error: null,
};

export const GetGalleryImagesPsoSlice = createSlice({
  name: 'calendar',
  initialState: GetGalleryImagesPsoinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        GetGalleryImagesPsoFUNC.pending,
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
        }
      )
      .addCase(
        GetGalleryImagesPsoFUNC.fulfilled,
        (state, action: PayloadAction<GetGalleryImagesPsoData[]>) => {
          if (action.payload) {
            state.isLoading = false;
            state.user = action.payload;

          }
        }
      )
      .addCase(
        GetGalleryImagesPsoFUNC.rejected,
        (state, action) => {
          if (action.payload instanceof Error) {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload.message;
          } else {
            state.isLoading = false;
            state.isError = true;
            state.error = "Error desconocido";
          }
        }
      )
      ;
  },
}).reducer;


// set imagen de perfil


export const SetImagePerfilPsoFUNC = createAsyncThunk(
  `auth/${SetImagePerfilPsoTypes.SET_IMAGE_PERFIL}`,
  async (payload: {
    cod_entidad: string;
    rol_entidad: string;
    visible: string;
    fecha: string;
    cod_pso: string;
    enable_blob: string;
    var_img_sd: string;
    var_img_hd: string;
  }, thunkAPI) => {
    try {

      
      const json_table = { "master_table": "asp_img_perfil_rol", "master_db_location_table": "asp_img_perfil_rol_location" };
      const json_location: any[] = [{ "field_vector": "1", "field_name": "rol_entidad" }, { "field_vector": "2", "field_name": "img_db_size" }];
      const json_v1 = {
        "cod_entidad": payload.cod_entidad,
        "fecha": payload.fecha,
        "rol_entidad": payload.rol_entidad,
        "cod_pso": payload.cod_pso,
        "enable_blob": payload.enable_blob,
        "img_id": "@SYS_IMG_ID_SERIAL",
        "img_mask": "@SYS_IMG_ID_MASK"
      };
      const json_v2 = [
        {
          "img_db_size": "200",
          "img_data": payload.var_img_sd,
          "img_mask": "S"
        },
        {
          "img_db_size": "1600",
          "img_data": payload.var_img_hd,
          "img_mask": "H"
        }];
      const json_v3 =
      {
        "img_date": payload.fecha,
        "img_text": "imagen de perfil",
        "img_tag": "tags"
      };

      // Combinar los objetos en uno solo
      const combinedObject = {
        json_table,
        json_location,
        json_v1,
        json_v2,
        json_v3,
      };
      // console.log("combinedObject", combinedObject);
      // Convertir el objeto combinado a cadena JSON
      const var_send = JSON.stringify(combinedObject);
      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia_set.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: var_send,
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      try {
        const result: SetImagePerfilPsoData = await response.json();
        // console.log("SetImagePerfilCfData", result);
        return result;

      } catch (jsonError) {
        throw new Error(`Error al procesar la respuesta JSON: `);
      }
    } catch (fetchError) {
      throw new Error(`Error de autenticación: `);
    }
  }
);

const SetImagePerfilPsoinitialState: SetImagePerfilPsoState = {
  rsp: null,
  isRsp: false,
  isLoading: false,
  isError: false,
  error: null,
};

export const SetImagePerfilPsoSlice = createSlice({
  name: 'SetImagePerfilPso',
  initialState: SetImagePerfilPsoinitialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(
              SetImagePerfilPsoFUNC.pending,
              (state) => {
                  state.isLoading = true;
                  state.isError = false;
                  state.error = null;
                  state.isRsp = false;
              }
          )
          .addCase(
              SetImagePerfilPsoFUNC.fulfilled,
              (state, action: PayloadAction<SetImagePerfilPsoData>) => {
                  if (action.payload) {
                      state.isLoading = false;
                      state.rsp = action.payload;
                      state.isRsp = true;

                  }
              }
          )
          .addCase(
              SetImagePerfilPsoFUNC.rejected,
              (state, action) => {
                  if (action.payload instanceof Error) {
                      state.isLoading = false;
                      state.isError = true;
                      state.error = action.payload.message;
                  } else {
                      state.isLoading = false;
                      state.isError = true;
                      state.error = "Error desconocido";
                  }
              }
          )
          ;
  },
}).reducer;