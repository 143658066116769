export type GetPreciosPsoListPswData = {
  
  cod_pso: string;
  cod_loc_3: string;
  cod_sede: string;
  cod_servicio: string;
  valor_tarifa: string;
  valor_psw: string;
  valor_abono: string;
  cod_psw: string;
  // des_servicio: string;
  // nemo_servicio: string;

}


export enum GetPreciosPsoListPswTypes {
  GET_PRECIOS_LIST_PSW_PSO = 'GET_PRECIOS_LIST_PSW_PSO',
}

export type GetPreciosPsoListPswState = {
  rsp: GetPreciosPsoListPswData[] | null;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;
};

// ---------------------------------------------------------------
export type UpdServPsoData = {
  
  cod_pso: string;
  cod_loc_3: string;
  cod_sede: string;
  cod_servicio: string;
  valor_tarifa: string;
  cod_persona: string;
  des_servicio: string;
  nemo_servicio: string;

}


export enum UpdServPsoTypes {
  UPD_PRECIO_PSO = 'UPD_PRECIO_PSO',
}

export type UpdServPsoState = {
  rsp: UpdServPsoData[] | null;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;
};

// ---------------------------------------------------------------
export type SetImageServData = {
  pso: string;
  localidad: string;
  sede: string;
  pso_nemo: string;
  loc_nemo: string;
  sede_nemo: string;

}


export enum SetImageServTypes {
  SET_IMAGE_TRANSFERENCIA = 'SET_IMAGE_TRANSFERENCIA',
}

export type SetImageServState = {
  rsp: SetImageServData | null;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;
};
// ---------------------------------------------------------------
export type GetImageServData = {
  pso: string;
  localidad: string;
  sede: string;
  pso_nemo: string;
  loc_nemo: string;
  sede_nemo: string;
  img_data: string;

}


export enum GetImageServTypes {
  GET_IMAGE_TRANSFERENCIA = 'GET_IMAGE_TRANSFERENCIA',
}

export type GetImageServState = {
  rsp: GetImageServData | null;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;
};

// ---------------------------------------------------------------
export type GetGalleryImgServData = {
  pso: string;
  localidad: string;
  sede: string;
  pso_nemo: string;
  loc_nemo: string;
  sede_nemo: string;
  img_data: string;

}


export enum GetGalleryImgServTypes {
  GET_GALLERY_IMAGE_TRANSFERENCIA = 'GET_GALLERY_IMAGE_TRANSFERENCIA',
}

export type GetGalleryImgServState = {
  rsp: GetGalleryImgServData[] | null;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;
};
