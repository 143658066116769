import axios from 'axios';

const API_BASE_URL = 'https://www.ibloom.cl/conexion';

export const checkUserRegistration = async (GlobalUser: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api_iapp_existe_registro.php`, {
            params: { GlobalUser }
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error en checkUserRegistration:', error);
        throw error;
    }
};

export const registerUser = async (userData: any) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api_iapp_registro.php`, userData);
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error en registerUser:', error);
        throw error;
    }
};
