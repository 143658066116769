// authSlice.ts
import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthActionTypes, AuthState, AuthUser, AuthUserIni } from './types';
import { GetTYC_CfTypes, GetTYC_CfState, GetTYC_CfData } from './types';


const initialState: AuthState = {
  user: AuthUserIni,
  isAuthenticated: false,
  isLoading: false,
  isQuest: false,
  isError: false,
  error: null,
};

// Define la acción asíncrona para iniciar sesión
export const checkUserRegistration = createAsyncThunk(
  `auth/${AuthActionTypes.SIGN_IN}`,
  async (payload: { GlobalUser: string, }, thunkAPI) => {
    let rsp = 0;
    try {
      console.log("payload", payload)
      // const additionalParams = {
      //   customHeader: 'value',
      //   anotherParam: 'anotherValue',
      //   params: { payload.GlobalUser }
      // };
      const json_sql = { "GlobalUser": payload.GlobalUser };

      const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_existe_registro_post.php", {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        //   ...additionalParams,
        // },
        body: JSON.stringify(json_sql),
      });
      // Simulando una solicitud de autenticación exitosa

      // console.log("payload", payload);
      // console.log("JSON.stringify(payload)", JSON.stringify(payload));
      // console.warn(response);
      // Manejar la respuesta de la segunda solicitud
      try {
        const result_0 = await response.json();
        rsp = result_0[0].RSP;
        const result = result_0[0];
        console.log("resp", result_0[0]);
        if (rsp == 1) {

          const data: AuthUser  = {
            token: '1',
            nemo_persona: result.nemo_persona,
            nombre_persona: result.nombre_persona,
            apat_persona: result.apat_persona,
            amat_persona: result.amat_persona,
            fono_persona: result.fono_persona,
            dir_text: result.dir_text,
            dir_num: result.dir_num,
            mail_persona: result.mail_persona,
            rut_persona: result.rut_persona,
            dv_persona: result.dv_persona,
            cod_persona: result.cod_persona,
            user_type: result.user_type,            
            cod_pso: result.cod_pso,
            cod_loc: result.cod_loc,
            cod_sede: result.cod_sede,
            RSP: result.RSP

          };

          return data;
        } if (rsp == 0) {
          
          const data: AuthUser  = {
            token: '',
            nemo_persona: '',
            nombre_persona: '',
            apat_persona: '',
            amat_persona: '',
            fono_persona: '',
            dir_text: '',
            dir_num: '',
            mail_persona: '',
            rut_persona: '',
            dv_persona: '',
            cod_persona: '',
            user_type: '',            
            cod_pso: '',
            cod_loc: '',
            cod_sede: '',
            RSP: '0'

          };

          return data;
        } else {
          return thunkAPI.rejectWithValue({ error: "El usuario y/o contraseña son incorrectos" });
        }
        // console.log("Resultado de la segunda solicitud:", result);
      } catch (error) {
        // console.warn(error);
        // console.error("Error al procesar la respuesta de la segunda solicitud:", error);
      }

    } catch (error: unknown) {
      // Simulando una solicitud de autenticación fallida
      return thunkAPI.rejectWithValue({ error: "Error de autenticación" });
    }
  }
);

export const updateUserApp = createAction<{ user: AuthUser }>('auth/updateUser');


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        checkUserRegistration.pending,
        (state) => {
          state.isLoading = true;
        }
      )
      .addCase(
        checkUserRegistration.fulfilled,
        (state, action) => {
          if (action.payload) {
            // console.log(action)
            state.isLoading = false;
            state.isAuthenticated = true;
            state.isQuest = true;
            state.user = action.payload;
          }
        }
      )
      .addCase(
        checkUserRegistration.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.error = action.payload;
        }
      )
      .addCase(updateUserApp, (state, action) => {
        state.user = action.payload.user;
      });
  },
}).reducer;

// export default authSlice.reducer;

// --------------------------------------------------
// Trae LOS TERMINOS Y CONDICIONES ACTUALES PARA CF
export const GetTYC_CfFUNC = createAsyncThunk(
  `auth/${GetTYC_CfTypes.GET_TYC_CF}`,
  async (payload: { id_q: string}, thunkAPI) => {
      try {
          const json_sql = { "sql_id": payload.id_q };
          const json_vc: any[] = [];
          const json_ve = {  };
          const json_vi = {};

          // Combinar los objetos en uno solo
          const combinedObject = {
              json_sql,
              json_vc,
              json_ve,
              json_vi
          };
          // console.log("combinedObject", combinedObject);
          // Convertir el objeto combinado a cadena JSON
          const var_send = JSON.stringify(combinedObject);
          const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: var_send,
          });

          if (!response.ok) {
              throw new Error(`Error en la solicitud: ${response.status}`);
          }

          try {
              const result: GetTYC_CfData = await response.json();
              // console.log("GetTYC_CfData", result);
              return result;

          } catch (jsonError) {
              throw new Error(`Error al procesar la respuesta JSON: `);
          }
      } catch (fetchError) {
          throw new Error(`Error de autenticación: `);
      }
  }
);

const GetTYC_CfinitialState: GetTYC_CfState = {
  rsp: null,
  isRsp: false,
  isLoading: false,
  isError: false,
  error: null,
};

export const GetTYC_CfSlice = createSlice({
  name: 'GetTYC_Cf',
  initialState: GetTYC_CfinitialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(
              GetTYC_CfFUNC.pending,
              (state) => {
                  state.isLoading = true;
                  state.isError = false;
                  state.error = null;
                  state.isRsp = false;
              }
          )
          .addCase(
              GetTYC_CfFUNC.fulfilled,
              (state, action: PayloadAction<GetTYC_CfData>) => {
                  if (action.payload) {
                      state.isLoading = false;
                      state.rsp = action.payload;
                  state.isRsp = true;

                  }
              }
          )
          .addCase(
              GetTYC_CfFUNC.rejected,
              (state, action) => {
                  if (action.payload instanceof Error) {
                      state.isLoading = false;
                      state.isError = true;
                      state.error = action.payload.message;
                  } else {
                      state.isLoading = false;
                      state.isError = true;
                      state.error = "Error desconocido";
                  }
              }
          )
          ;
  },
}).reducer;
