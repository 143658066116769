// ./store/variablesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from './index'; // Asume que has definido RootState en './store'
import { RootState } from '../../model/store';

interface VariablesState {
  [key: string]: string | string[];
}

const initialState: VariablesState = {};

const variablesSlice = createSlice({
  name: 'variables',
  initialState,
  reducers: {
    setVariable(
      state,
      action: PayloadAction<{ key: string; value: string | string[] }>
    ) {
      const { key, value } = action.payload;
      state[key] = value;
    },
    deleteVariable(state, action: PayloadAction<string>) {
      delete state[action.payload];
    },
  },
});

export const { setVariable, deleteVariable } = variablesSlice.actions;

export const selectVariable = (state: RootState, key: string) =>
  state.Variables[key];

export default variablesSlice.reducer;
