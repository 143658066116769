

  export enum GetGalleryImagesCfTypes {
    GET_GALLERY_IMAGE_CF = 'GET_GALLERY_IMAGE_CF',
  }
  
  export type GetGalleryImagesCfData = {
    fecha: string;
    cod_pso: string;
    cod_psw: string;
    cod_cf: string;
    cod_loc: string;
    cod_serv: string;
    des_servicio: string;
    nemo_servicio: string;
    image_desc: string;
    image_type: string;
    img_data: string;
    img_id: string;
  
  }
  
  
  export type GetGalleryImagesCfState = {
    user: GetGalleryImagesCfData[] | null;
    isLoading: boolean;
    isRsp: boolean;
    isError: boolean;
    error: string | null | unknown;
  };




export enum GetDescriptionCfTypes {
  GET_DESCRIPTION_CF = 'GET_DESCRIPTION_CF',
}

export type GetDescriptionCfData = {
  Empresa: string;
  Termino: string;
  Localidad: string;
  Sede: string;
  Servicio: string;
  Estado: string;
  Cliente:string;
  Inicio:string;
  Fecha:string;
  ClienteRut:string;
  cod_loc: string;

}


export type GetDescriptionCfState = {
  user: GetDescriptionCfData[] | null;
  isUser: boolean;
  // isRsp: boolean;
  isLoading: boolean;
  isError: boolean;
  error: string | null | unknown;
};

export enum GetImageProfileCfTypes {
  GET_IMAGE_PROFILE_CF = 'GET_IMAGE_PROFILE_CF',
}

export type GetImageProfileCfData = {
  fecha: string;
  cod_entidad: string;
  rol_entidad: string;
  cod_pso: string;
  visible: string;
  img_data: string;
  img_id: string;

}


export type GetImageProfileCfState = {
  user: GetImageProfileCfData[] | null;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;
};

export type RootState = {
  GetImageProfileCf: GetImageProfileCfState;
  GetDescriptionCf: GetDescriptionCfState;
  GetGalleryImagesCf: GetGalleryImagesCfState;
}


// --------------------------------------------------------
export type SetImagePerfilCfData = {
  pso: string;
  localidad: string;
  sede: string;
  pso_nemo: string;
  loc_nemo: string;
  sede_nemo: string;

}


export enum SetImagePerfilCfTypes {
  SET_IMAGE_PERFIL = 'SET_IMAGE_PERFIL',
}

export type SetImagePerfilCfState = {
  rsp: SetImagePerfilCfData | null;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;
};