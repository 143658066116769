

import { GetPreciosPsoListPswTypes, GetPreciosPsoListPswState, GetPreciosPsoListPswData } from './types';
import { UpdServPsoTypes, UpdServPsoState, UpdServPsoData } from './types';
import { SetImageServTypes, SetImageServState, SetImageServData } from './types';
import { GetImageServTypes, GetImageServState, GetImageServData } from './types';
import { GetGalleryImgServTypes, GetGalleryImgServState, GetGalleryImgServData } from './types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Trae los psw de una pso
export const GetPreciosPsoListPswFUNC = createAsyncThunk(
  `auth/${GetPreciosPsoListPswTypes.GET_PRECIOS_LIST_PSW_PSO}`,
  async (payload: { id_q: string, 
    GlobalPso: string 
    GlobalSize: string 
}, thunkAPI) => {
      try {
          const json_sql = { "sql_id": payload.id_q };
          const json_vc: any[] = [];
          const json_ve = { 
            "GlobalPso": payload.GlobalPso ,
            "GlobalSize": payload.GlobalSize ,
        };
          const json_vi = {};

          // Combinar los objetos en uno solo
          const combinedObject = {
              json_sql,
              json_vc,
              json_ve,
              json_vi
          };
          console.log("combinedObject", combinedObject);
          // Convertir el objeto combinado a cadena JSON
          const var_send = JSON.stringify(combinedObject);
          const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia.php", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: var_send,
          });

          if (!response.ok) {
              throw new Error(`Error en la solicitud: ${response.status}`);
          }

          try {
              const result: GetPreciosPsoListPswData[] = await response.json();
              // console.log("GetPreciosPsoListPswData", result);
              return result;

          } catch (jsonError) {
              throw new Error(`Error al procesar la respuesta JSON: `);
          }
      } catch (fetchError) {
          throw new Error(`Error de autenticación: `);
      }
  }
);

const GetPreciosPsoListPswinitialState: GetPreciosPsoListPswState = {
  rsp: null,
  isRsp: false,
  isLoading: false,
  isError: false,
  error: null,
};

export const GetPreciosPsoListPswSlice = createSlice({
  name: 'calendar',
  initialState: GetPreciosPsoListPswinitialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(
              GetPreciosPsoListPswFUNC.pending,
              (state) => {
                  state.isLoading = true;
                  state.isError = false;
                  state.error = null;
                  state.isRsp = false;
              }
          )
          .addCase(
              GetPreciosPsoListPswFUNC.fulfilled,
              (state, action: PayloadAction<GetPreciosPsoListPswData[]>) => {
                  if (action.payload) {
                      state.isLoading = false;
                      state.rsp = action.payload;
                      state.isRsp = true;

                  }
              }
          )
          .addCase(
              GetPreciosPsoListPswFUNC.rejected,
              (state, action) => {
                  if (action.payload instanceof Error) {
                      state.isLoading = false;
                      state.isError = true;
                      state.error = action.payload.message;
                  } else {
                      state.isLoading = false;
                      state.isError = true;
                      state.error = "Error desconocido";
                  }
              }
          )
          ;
  },
}).reducer;

// ACTUALIZA PRECIO SERVICIO PSO
export const UpdServPsoFUNC = createAsyncThunk(
  `auth/${UpdServPsoTypes.UPD_PRECIO_PSO}`,
  async (payload: { 
    id_q: string, 
    GlobalPso: string ,
    GlobalPsw: string ,
    GlobalServ: string ,
    GlobalTarifa: string ,
    GlobalTarifaPsw: string ,
    GlobalLoc: string ,
    GlobalSede: string ,
}, thunkAPI) => {
      try {
          const json_sql = { "sql_id": payload.id_q };
          const json_vc: any[] = [];
          const json_ve = { 
            "GlobalPso": payload.GlobalPso ,
            "GlobalPsw": payload.GlobalPsw ,
            "GlobalServ": payload.GlobalServ ,
            "GlobalTarifa": payload.GlobalTarifa ,
            "GlobalTarifaPsw": payload.GlobalTarifaPsw ,
            "GlobalLoc": payload.GlobalLoc ,
            "GlobalSede": payload.GlobalSede ,
        };
          const json_vi = {};

          // Combinar los objetos en uno solo
          const combinedObject = {
              json_sql,
              json_vc,
              json_ve,
              json_vi
          };
        //   console.log("combinedObject", combinedObject);
          // Convertir el objeto combinado a cadena JSON
          const var_send = JSON.stringify(combinedObject);
          const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: var_send,
          });

          if (!response.ok) {
              throw new Error(`Error en la solicitud: ${response.status}`);
          }

          try {
              const result: UpdServPsoData[] = await response.json();
              // console.log("UpdServPsoData", result);
              return result;

          } catch (jsonError) {
              throw new Error(`Error al procesar la respuesta JSON: `);
          }
      } catch (fetchError) {
          throw new Error(`Error de autenticación: `);
      }
  }
);

const UpdServPsoInitialState: UpdServPsoState = {
  rsp: null,
  isRsp: false,
  isLoading: false,
  isError: false,
  error: null,
};

export const UpdServPsoSlice = createSlice({
  name: 'calendar',
  initialState: UpdServPsoInitialState,
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(
            UpdServPsoFUNC.pending,
              (state) => {
                  state.isLoading = true;
                  state.isError = false;
                  state.error = null;
                  state.isRsp = false;
              }
          )
        //   .addCase(
        //     UpdServPsoFUNC.fulfilled,
        //       (state, action: PayloadAction<UpdServPsoData[]>) => {
        //           if (action.payload) {
        //               state.isLoading = false;
        //               state.rsp = action.payload;
        //               state.isRsp = true;

        //           }
        //       }
        //   )
          .addCase(
            UpdServPsoFUNC.rejected,
              (state, action) => {
                  if (action.payload instanceof Error) {
                      state.isLoading = false;
                      state.isError = true;
                      state.error = action.payload.message;
                  } else {
                      state.isLoading = false;
                      state.isError = true;
                      state.error = "Error desconocido";
                  }
              }
          )
          ;
  },
}).reducer;


// set imagen de servicio
export const SetImageServFUNC = createAsyncThunk(
    `auth/${SetImageServTypes.SET_IMAGE_TRANSFERENCIA}`,
    async (payload: {
        cod_pso: string;
        cod_psw: string;
        cod_cf: string;
        var_date: string;
        var_loc: string;
        var_sede: string;
        var_serv: string;
        var_img_desc: string;
        var_img_tag: string;
        var_img_sd: string;
        var_img_hd: string;
    }, thunkAPI) => {
        try {
            const json_table = { "master_table": "asp_img_catalogo_servicios", "master_db_location_table": "asp_img_catalogo_servicios_location" };
            const json_location: any[] = [{ "field_vector": "1", "field_name": "cod_pso" }, { "field_vector": "2", "field_name": "img_db_size" }];
            const json_v1 = {
                "fecha": payload.var_date,
                "cod_psw": payload.cod_psw,
                "cod_pso": payload.cod_pso,
                "cod_loc_servicio": payload.var_loc,
                "cod_sede": payload.var_sede,
                "cod_servicio": payload.var_serv,
                "enable_blob": "0",
                "img_id": "@SYS_IMG_ID_SERIAL",
                "img_mask": "@SYS_IMG_ID_MASK"
            };
            const json_v2 = [
                {
                    "img_db_size": "200",
                    "img_data": payload.var_img_sd,
                    "img_mask": "S"
                },
                {
                    "img_db_size": "1600",
                    "img_data": payload.var_img_hd,
                    "img_mask": "H"
                }];
            const json_v3 =
            {
                "img_date": payload.var_date,
                "img_text": payload.var_img_desc,
                "img_tag": payload.var_img_tag
            };

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_table,
                json_location,
                json_v1,
                json_v2,
                json_v3,
            };
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia_set.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: SetImageServData = await response.json();
                console.log("SetImageServData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const SetImageServinitialState: SetImageServState = {
    rsp: null,
    isRsp: false,
    isLoading: false,
    isError: false,
    error: null,
};

export const SetImageServSlice = createSlice({
    name: 'SetImageServ',
    initialState: SetImageServinitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                SetImageServFUNC.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                    state.isRsp = false;
                }
            )
            .addCase(
                SetImageServFUNC.fulfilled,
                (state, action: PayloadAction<SetImageServData>) => {
                    if (action.payload) {
                        console.log("PayloadAction SetImageServData")
                        state.isLoading = false;
                        state.rsp = action.payload;
                        state.isRsp = true;

                    }
                }
            )
            .addCase(
                SetImageServFUNC.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;

// Get imagen de servicio visible
export const GetImageServFUNC = createAsyncThunk(
    `auth/${GetImageServTypes.GET_IMAGE_TRANSFERENCIA}`,
    async (payload: {
        id_q: string;
        GlobalPso: string;
        GlobalPsw: string;
        GlobalLoc: string;
        GlobalSede: string;
        GlobalServ: string;
    }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
          const json_vc: any[] = [];
          const json_ve = { 
            "GlobalPso": payload.GlobalPso ,
            "GlobalPsw": payload.GlobalPsw ,
            "GlobalLoc": payload.GlobalLoc ,
            "GlobalSede": payload.GlobalSede ,
            "GlobalServ": payload.GlobalServ ,
        };
          const json_vi = {};

          // Combinar los objetos en uno solo
          const combinedObject = {
              json_sql,
              json_vc,
              json_ve,
              json_vi
          };
            console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: GetImageServData = await response.json();
                console.log("GetImageServData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const GetImageServInitialState: GetImageServState = {
    rsp: null,
    isRsp: false,
    isLoading: false,
    isError: false,
    error: null,
};

export const GetImageServSlice = createSlice({
    name: 'GetImageServ',
    initialState: GetImageServInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                GetImageServFUNC.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                    state.isRsp = false;
                }
            )
            .addCase(
                GetImageServFUNC.fulfilled,
                (state, action: PayloadAction<GetImageServData>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;
                        state.isRsp = true;

                    }
                }
            )
            .addCase(
                GetImageServFUNC.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;


// Get galeria de imagenes de servicio visible
export const GetGalleryImgServFUNC = createAsyncThunk(
    `auth/${GetGalleryImgServTypes.GET_GALLERY_IMAGE_TRANSFERENCIA}`,
    async (payload: {
        id_q: string;
        GlobalPso: string;
        GlobalPsw: string;
        GlobalLoc: string;
        GlobalSede: string;
        GlobalServ: string;
        GlobalSize: string;
    }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
          const json_vc: any[] = [];
          const json_ve = { 
            "GlobalPso": payload.GlobalPso ,
            "GlobalPsw": payload.GlobalPsw ,
            "GlobalLoc": payload.GlobalLoc ,
            "GlobalSede": payload.GlobalSede ,
            "GlobalServ": payload.GlobalServ ,
            "GlobalSize": payload.GlobalSize ,
        };
          const json_vi = {};

          // Combinar los objetos en uno solo
          const combinedObject = {
              json_sql,
              json_vc,
              json_ve,
              json_vi
          };
            console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp_multimedia.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: GetGalleryImgServData[] = await response.json();
                console.log("GetGalleryImgServData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const GetGalleryImgServInitialState: GetGalleryImgServState = {
    rsp: null,
    isRsp: false,
    isLoading: false,
    isError: false,
    error: null,
};

export const GetGalleryImgServSlice = createSlice({
    name: 'GetGalleryImgServ',
    initialState: GetGalleryImgServInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                GetGalleryImgServFUNC.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                    state.isRsp = false;
                }
            )
            .addCase(
                GetGalleryImgServFUNC.fulfilled,
                (state, action: PayloadAction<GetGalleryImgServData[]>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;
                        state.isRsp = true;

                    }
                }
            )
            .addCase(
                GetGalleryImgServFUNC.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;