

// ---------------------------------------------------------------
  export enum GetGalleryImagesPsoTypes {
    GET_GALLERY_IMAGE_PSO = 'GET_GALLERY_IMAGE_PSO',
  }
  
  export type GetGalleryImagesPsoData = {
    fecha: string;
    cod_pso: string;
    cod_psw: string;
    cod_cf: string;
    cod_loc: string;
    cod_serv: string;
    des_servicio: string;
    nemo_servicio: string;
    image_desc: string;
    image_type: string;
    img_data: string;
    img_id: string;
  
  }
  
  
  export type GetGalleryImagesPsoState = {
    user: GetGalleryImagesPsoData[] | null;
    isLoading: boolean;
    isError: boolean;
    error: string | null | unknown;
  };



// ---------------------------------------------------------------

export enum GetDescriptionPsoTypes {
  GET_DESCRIPTION_PSO = 'GET_DESCRIPTION_PSO',
}

export type GetDescriptionPsoData = {
  Empresa: string;
  Termino: string;
  Localidad: string;
  Sede: string;
  Servicio: string;
  Estado: string;
  Cliente:string;
  Inicio:string;
  Fecha:string;
  ClienteRut:string;
  cod_loc: string;

}


export type GetDescriptionPsoState = {
  user: GetDescriptionPsoData[] | null;
  isUser: boolean;
  isLoading: boolean;
  isError: boolean;
  error: string | null | unknown;
};

// ---------------------------------------------------------------
export type GetImageProfilePsoData = {
  fecha: string;
  cod_entidad: string;
  rol_entidad: string;
  cod_pso: string;
  visible: string;
  img_data: string;
  img_id: string;

}


export enum GetImageProfilePsoTypes {
  GET_IMAGE_PERFIL_PSO = 'GET_IMAGE_PERFIL_PSO',
}

export type GetImageProfilePsoState = {
  rsp: GetImageProfilePsoData[] | null;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;
};


// ----------------------------------------------
export type SetImagePerfilPsoData = {
  pso: string;
  localidad: string;
  sede: string;
  pso_nemo: string;
  loc_nemo: string;
  sede_nemo: string;

}


export enum SetImagePerfilPsoTypes {
  SET_IMAGE_PERFIL = 'SET_IMAGE_PERFIL',
}

export type SetImagePerfilPsoState = {
  rsp: SetImagePerfilPsoData | null;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;
};

// ---------------------------------------------------------------
export type RootState = {
  GetImageProfilePso: GetImageProfilePsoState;
  GetDescriptionPso: GetDescriptionPsoState;
  GetGalleryImagesPso: GetGalleryImagesPsoState;
}