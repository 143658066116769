export type SetImageTransferenciaData = {
  pso: string;
  localidad: string;
  sede: string;
  pso_nemo: string;
  loc_nemo: string;
  sede_nemo: string;

}


export enum SetImageTransferenciaTypes {
  SET_IMAGE_TRANSFERENCIA = 'SET_IMAGE_TRANSFERENCIA',
}

export type SetImageTransferenciaState = {
  rsp: SetImageTransferenciaData | null;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;
};

// ----------------------------------------------------------------------

export enum GetImageTransferTypes {
  GET_IMAGE_TRANSFER = 'GET_IMAGE_TRANSFER',
}

export type GetImageTransferData = {
  fecha: string;
  cod_pso: string;
  cod_psw: string;
  cod_cf: string;
  cod_loc: string;
  cod_serv: string;
  nemo_servicio: string;
  image_desc: string;
  image_type: string;
  img_data: string;
  img_id: string;

}

export type GetImageTransferState = {
  user: GetImageTransferData[] | null;
  isProfileGralenticated: boolean;
  isLoading: boolean;
  isRsp: boolean;
  isError: boolean;
  error: string | null | unknown;


};
