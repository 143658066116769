// la funcion que pregunta a la API
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetPsoLocCalTypesStep, GetPsoLocCalStateStep, GetPsoLocCalDataStep } from './InfoStepTypes';
import { GetPsoServDispTypesStep, GetPsoServDispStateStep, GetPsoServDispDataStep } from './InfoStepTypes';
import { GetHourDispTypesStep, GetHourDispStateStep, GetHourDispDataStep } from './InfoStepTypes';
import { GetPswDispTypesStep, GetPswDispStateStep, GetPswDispDataStep } from './InfoStepTypes';
import { GetCfDispTypesStep, GetCfDispStateStep, GetCfDispDataStep } from './InfoStepTypes';
import { SetPsoReservaTypes, SetPsoReservaState, SetPsoReservaData } from './InfoStepTypes';
import { SetLockHoraTypes, SetLockHoraState, SetLockHoraData } from './InfoStepTypes';

// trae los pso, localidades y sedes
export const GetPsoLocCalFUNC_STEP = createAsyncThunk(
    `auth/${GetPsoLocCalTypesStep.GET_INFO_USER_CAL_STEP}`,
    async (payload: { id_q: string, GlobalUser: string }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
            const json_vc: any[] = [];
            const json_ve = { "GlobalUser": payload.GlobalUser };
            const json_vi = {};

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_sql,
                json_vc,
                json_ve,
                json_vi
            };
            // console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: GetPsoLocCalDataStep[] = await response.json();
                // console.log("GetPsoLocCalData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const GetPsoLocCalinitialState_STEP: GetPsoLocCalStateStep = {
    rsp: null,
    isLoading: false,
    isError: false,
    error: null,
};

export const GetPsoLocCalSlice_STEP = createSlice({
    name: 'calendar',
    initialState: GetPsoLocCalinitialState_STEP,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                GetPsoLocCalFUNC_STEP.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                }
            )
            .addCase(
                GetPsoLocCalFUNC_STEP.fulfilled,
                (state, action: PayloadAction<GetPsoLocCalDataStep[]>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;

                    }
                }
            )
            .addCase(
                GetPsoLocCalFUNC_STEP.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;

// trae los pso, localidades y sedes
export const GetPsoServDispFUNC_STEP = createAsyncThunk(
    `auth/${GetPsoServDispTypesStep.GET_PSO_SERV_DISP_STEP}`,
    async (payload: { id_q: string, GlobalUser: string, GlobalDateIni: string, GlobalDateEnd: string, GlobalPso: string, GlobalLoc: string, GlobalSede: string, GlobalPsw: string }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
            const json_vc: any[] = [];
            const json_ve = {
                "GlobalUser": payload.GlobalUser,
                "GlobalDateIni": payload.GlobalDateIni,
                "GlobalDateEnd": payload.GlobalDateEnd,
                "GlobalPso": payload.GlobalPso,
                "GlobalLoc": payload.GlobalLoc,
                "GlobalSede": payload.GlobalSede,
                "GlobalPsw": payload.GlobalPsw,
            };
            const json_vi = {};

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_sql,
                json_vc,
                json_ve,
                json_vi
            };
            console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: GetPsoServDispDataStep[] = await response.json();
                console.log("GetPsoServDispFUNC_STEP result", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const GetPsoServDispinitialState_STEP: GetPsoServDispStateStep = {
    rsp: null,
    isLoading: false,
    isError: false,
    error: null,
};

export const GetPsoServDispSlice_STEP = createSlice({
    name: 'calendar',
    initialState: GetPsoServDispinitialState_STEP,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                GetPsoServDispFUNC_STEP.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                }
            )
            .addCase(
                GetPsoServDispFUNC_STEP.fulfilled,
                (state, action: PayloadAction<GetPsoServDispDataStep[]>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;

                    }
                }
            )
            .addCase(
                GetPsoServDispFUNC_STEP.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;

// trae los trabajadores disponibles 
export const GetPswDispFUNC_STEP = createAsyncThunk(
    `auth/${GetPswDispTypesStep.GET_PSW_DISP_STEP}`,
    async (payload: { id_q: string, GlobalUser: string, currentDate: string, GlobalPSO: string, GlobalLOC: string, GlobalSEDE: string, GlobalSERV: string }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
            const json_vc: any[] = [];
            const json_ve = {
                "GlobalUser": payload.GlobalUser,
                "currentDate": payload.currentDate,
                "GlobalPSO": payload.GlobalPSO,
                "GlobalLOC": payload.GlobalLOC,
                "GlobalSEDE": payload.GlobalSEDE,
            };
            const json_vi = {};

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_sql,
                json_vc,
                json_ve,
                json_vi
            };
            // console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: GetPswDispDataStep[] = await response.json();
                // console.log("GetPsoLocCalData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const GetPswDispinitialState_STEP: GetPswDispStateStep = {
    rsp: null,
    isLoading: false,
    isError: false,
    error: null,
};

export const GetPswDispSlice_STEP = createSlice({
    name: 'calendar',
    initialState: GetPswDispinitialState_STEP,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                GetPswDispFUNC_STEP.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                }
            )
            .addCase(
                GetPswDispFUNC_STEP.fulfilled,
                (state, action: PayloadAction<GetPswDispDataStep[]>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;

                    }
                }
            )
            .addCase(
                GetPswDispFUNC_STEP.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;


// trae los horarios disponibles
export const GetHourDispFUNC_STEP = createAsyncThunk(
    `auth/${GetHourDispTypesStep.GET_HOUR_DISP_STEP}`,
    async (payload:
        {
            id_q: string,
            GlobalUser: string,
            GlobalDateIni: string,
            GlobalDateEnd: string,
            GlobalPso: string,
            GlobalLoc: string,
            GlobalSede: string,
            GlobalServ: string,
            GlobalPsw: string,
        }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
            const json_vc: any[] = [];
            const json_ve = {
                "GlobalUser": payload.GlobalUser,
                "GlobalDateIni": payload.GlobalDateIni,
                "GlobalDateEnd": payload.GlobalDateEnd,
                "GlobalPso": payload.GlobalPso,
                "GlobalLoc": payload.GlobalLoc,
                "GlobalSede": payload.GlobalSede,
            };
            const json_vi = {};

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_sql,
                json_vc,
                json_ve,
                json_vi
            };
            // console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: GetHourDispDataStep[] = await response.json();
                // console.log("GetPsoLocCalData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const GetHourDispinitialState_STEP: GetHourDispStateStep = {
    rsp: null,
    isLoading: false,
    isError: false,
    error: null,
};

export const GetHourDispSlice_STEP = createSlice({
    name: 'calendar',
    initialState: GetHourDispinitialState_STEP,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                GetHourDispFUNC_STEP.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                }
            )
            .addCase(
                GetHourDispFUNC_STEP.fulfilled,
                (state, action: PayloadAction<GetHourDispDataStep[]>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;

                    }
                }
            )
            .addCase(
                GetHourDispFUNC_STEP.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;

export const GetCfDispFUNC_STEP = createAsyncThunk(
    `auth/${GetCfDispTypesStep.GET_CF_DISP_STEP}`,
    async (payload:
        {
            id_q: string,
            GlobalUser: string
            GlobalDateTime: string,
            GlobalDate: string,
            GlobalPso: string,
            GlobalLoc: string,
            GlobalSede: string,
            GlobalPsw: string,
            GlobalServ: string,
        }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
            const json_vc: any[] = [];
            const json_ve = {
                "GlobalUser": payload.GlobalUser,
                "GlobalDateTime": payload.GlobalDateTime,
                "GlobalDate": payload.GlobalDate,
                "GlobalPso": payload.GlobalPso,
                "GlobalLoc": payload.GlobalLoc,
                "GlobalSede": payload.GlobalSede,
                "GlobalPsw": payload.GlobalPsw,
                "GlobalServ": payload.GlobalServ,
            };
            const json_vi = {};

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_sql,
                json_vc,
                json_ve,
                json_vi
            };
            // console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: GetCfDispDataStep[] = await response.json();
                // console.log("GetPsoLocCalData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const GetCfDispinitialState_STEP: GetCfDispStateStep = {
    rsp: null,
    isLoading: false,
    isError: false,
    error: null,
};

export const GetCfDispSlice_STEP = createSlice({
    name: 'calendar',
    initialState: GetCfDispinitialState_STEP,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                GetCfDispFUNC_STEP.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                }
            )
            .addCase(
                GetCfDispFUNC_STEP.fulfilled,
                (state, action: PayloadAction<GetCfDispDataStep[]>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;

                    }
                }
            )
            .addCase(
                GetCfDispFUNC_STEP.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;


// reserva 
// {"id_q":"SET_RESERVA_STEP",
// "GlobalUser":"formAA_global_psw_rut",
// "GlobalDateTime":"formAA_global_current_time",
// "GlobalDate":"formAA_global_current_date",
// "GlobalPso":"formAA_global_pso",
// "GlobalLoc":"formAA_global_loc",
// "GlobalSede":"formAA_global_sede",
// "GlobalPsw":"formAA_global_psw_rut",
// "GlobalServ":"formAA_global_srv",
// "GlobalCf":"formAA_global_cf"}


export const SetPsoReservaFUNC_STEP = createAsyncThunk(
    `auth/${SetPsoReservaTypes.SET_PSO_RESERVA_STEP}`,
    async (payload: {
        id_q: string,
        GlobalUser: string,
        GlobalDateTime: string,
        GlobalDate: string,
        GlobalPso: string,
        GlobalLoc: string,
        GlobalSede: string,
        GlobalPsw: string,
        GlobalServ: string,
        GlobalTime: string,
        GlobalCf: string
    }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
            const json_vc: any[] = [];
            const json_ve = {
                "GlobalUser": payload.GlobalUser,
                "GlobalDateTime": payload.GlobalDateTime,
                "GlobalDate": payload.GlobalDate,
                "GlobalPso": payload.GlobalPso,
                "GlobalLoc": payload.GlobalLoc,
                "GlobalSede": payload.GlobalSede,
                "GlobalPsw": payload.GlobalPsw,
                "GlobalServ": payload.GlobalServ,
                "GlobalTime": payload.GlobalTime,
                "GlobalCf": payload.GlobalCf,
            };
            const json_vi = {};
            // call GWA_SQL_EXEC_2( '{"sql_id":"GET_DISP_PSW"}', '[]', '{"GlobalUser":"19481852-1", "GlobalDate":"2024-01-19 10:00",
            // "GlobalPso":"IBLOOM", "GlobalLoc":"04101", "GlobalSede":"CASA", "GlobalServ":"SRV_PESTANA"}', '{}' );
            // Combinar los objetos en uno solo
            const combinedObject = {
                json_sql,
                json_vc,
                json_ve,
                json_vi
            };
            // console.log("SetHoraPswUser", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: SetPsoReservaData = await response.json();
                // console.log("result", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const IniStateSetPsoReserva: SetPsoReservaState = {
    ifReserva: null,
    isLoading: false,
    isError: false,
    error: null,
};

export const SetPsoReservaSlice = createSlice({
    name: 'match',
    initialState: IniStateSetPsoReserva,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                SetPsoReservaFUNC_STEP.fulfilled,
                (state, action: PayloadAction<SetPsoReservaData>) => {
                    if (action.payload) {
                        // console.log("action.payload", action.payload) // 
                        state.isLoading = false;
                        // Convierte el objeto a un array si es necesario
                        state.ifReserva = action.payload;
                        // console.log("state.profesion", state.profesion)
                    }
                }
            )
            .addCase(
                SetPsoReservaFUNC_STEP.rejected,
                (state, action) => {
                    console.error("Error fetching data:", action.error);
                    state.isLoading = false;
                    state.isError = true;
                    state.error = "Error fetching data";
                }
            );
    },
}).reducer;


// call GWA_SQL_EXEC_2( 
//     '{"sql_id":"SET_LOCK_HORA"}', 
//     '[]', 
//     '{"GlobalAction":"STEP1", "GlobalUser":"joseluiscorrearamos", "GlobalRol":"CF", "GlobalCF":"joseluiscorrearamos", "GlobalPsw":"provoste.ignacia",
//  "GlobalTime":"10:00", "GlobalDate":"2024-07-06", "GlobalPso":"IBLOOM", "GlobalLoc":"04101", "GlobalSede":"CASA", "GlobalServ":"SRV_PESTANA", "GlobalServTime":"120"}', 
//     '{}' );

// Pasos para reservar
export const SetLockHoraFUNC = createAsyncThunk(
    `auth/${SetLockHoraTypes.SET_LOCK_HORA}`,
    async (payload: { 
        
        id_q: string,
        GlobalUser: string,
        GlobalDateTime: string,
        GlobalDate: string,
        GlobalPso: string,
        GlobalLoc: string,
        GlobalSede: string,
        GlobalPsw: string,
        GlobalServ: string,
        GlobalTime: string,
        GlobalCf: string,
        GlobalAction: string,
        GlobalRol: string,
        GlobalServTime: string,
    }, thunkAPI) => {
        try {
            const json_sql = { "sql_id": payload.id_q };
            const json_vc: any[] = [];
            const json_ve = {
                "GlobalUser": payload.GlobalUser,
                "GlobalAction": payload.GlobalAction,
                "GlobalRol": payload.GlobalRol,
                "GlobalDateTime": payload.GlobalDateTime,
                "GlobalDate": payload.GlobalDate,
                "GlobalPso": payload.GlobalPso,
                "GlobalLoc": payload.GlobalLoc,
                "GlobalSede": payload.GlobalSede,
                "GlobalPsw": payload.GlobalPsw,
                "GlobalServ": payload.GlobalServ,
                "GlobalTime": payload.GlobalTime,
                "GlobalCF": payload.GlobalCf,
                "GlobalServTime": payload.GlobalServTime,
            };
            const json_vi = {};

            // Combinar los objetos en uno solo
            const combinedObject = {
                json_sql,
                json_vc,
                json_ve,
                json_vi
            };
            // console.log("combinedObject", combinedObject);
            // Convertir el objeto combinado a cadena JSON
            const var_send = JSON.stringify(combinedObject);
            const response = await fetch("https://www.ibloom.cl/conexion/api_iapp.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: var_send,
            });

            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            try {
                const result: SetLockHoraData = await response.json();
                // console.log("SetLockHoraData", result);
                return result;

            } catch (jsonError) {
                throw new Error(`Error al procesar la respuesta JSON: `);
            }
        } catch (fetchError) {
            throw new Error(`Error de autenticación: `);
        }
    }
);

const SetLockHorainitialState: SetLockHoraState = {
    rsp: null,
    isRsp: false,
    isLoading: false,
    isError: false,
    error: null,
};

export const SetLockHoraSlice = createSlice({
    name: 'SetLockHora',
    initialState: SetLockHorainitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                SetLockHoraFUNC.pending,
                (state) => {
                    state.isLoading = true;
                    state.isError = false;
                    state.error = null;
                    state.isRsp = false;
                }
            )
            .addCase(
                SetLockHoraFUNC.fulfilled,
                (state, action: PayloadAction<SetLockHoraData>) => {
                    if (action.payload) {
                        state.isLoading = false;
                        state.rsp = action.payload;
                    state.isRsp = true;

                    }
                }
            )
            .addCase(
                SetLockHoraFUNC.rejected,
                (state, action) => {
                    if (action.payload instanceof Error) {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = action.payload.message;
                    } else {
                        state.isLoading = false;
                        state.isError = true;
                        state.error = "Error desconocido";
                    }
                }
            )
            ;
    },
}).reducer;


