
  export enum GetStepPsoTypes {
    GET_STEP_PSO = 'GET_STEP_PSO',
  }
  
  // `cod_rubro`, `cod_origen`, `cod_profesion`, `cod_especialidad`, `num_step`, `var_sql_id`, `var_params`, `var_slice`, 
  // `var_result_obj`, `var_action`, `var_type_frame`, `var_title`, `var_title_search`, `var_text_search`, `var_text_view`

  export type GetStepPsoData = {
    cod_rubro: string;
    cod_origen:string;
    cod_profesion: string;
    cod_especialidad: string;
    num_step: string;
    type_step: string;
    var_sql_id: string;
    var_params: string;
    var_params_selected: string;
    var_slice: string;
    var_type_modal: string;
    var_result_obj: string;
    var_action: string;
    var_type_frame: string;
    var_title: string;
    var_title_search: string;
    var_text_search: string;
    var_text_view: string;
  
  }
  
  
  export type GetStepPsoState = {
    StepPso: GetStepPsoData[] | null;
    isLoading: boolean;
    isError: boolean;
    error: string | null | unknown;
  };


export type RootState = {
  StepPso: GetStepPsoState;
}