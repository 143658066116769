import React from 'react';
import { Button, Paper, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Registro.css';

interface MsgClienteconRegistroFormProps {
    pso: string;
}

const MsgClienteconRegistroForm: React.FC<MsgClienteconRegistroFormProps> = ({ pso }) => {
    const navigate = useNavigate();

    const handleAccept = () => {
        // Aquí puedes manejar la lógica que necesites al hacer clic en "Aceptar"
        navigate('/');
    };

    return (
        <div className="registro-container">
            <Typography variant="h4" component="h1" className="registro-title">
                {pso}
            </Typography>
            <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                <Typography variant="h5" component="h2" gutterBottom textAlign="center">
                ¡Qué gusto verte de nuevo! Ya cuentas con una cuenta con nosotros, lo cual nos alegra mucho. Si necesitas agendar algún servicio, estamos aquí para ayudarte en lo que necesites. ¡Nos encanta acompañarte en cada paso!
                </Typography>
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Button variant="contained" color="primary" onClick={handleAccept}>
                        Aceptar
                    </Button>
                </Box>
            </Paper>
        </div>
    );
};

export default MsgClienteconRegistroForm;
