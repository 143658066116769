import React, { useEffect, useRef, useState } from 'react';
import {
    TextField,
    Button,
    Box,
    Paper,
    Typography,
    Grid,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Collapse,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import './Registro.css'; // Importar el archivo de estilos
import { functionMap } from '../../model/storeFunc';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelectorBySlice } from '../../model/store';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { getNames } from 'country-list';

interface RegistrationFormProps {
    email: string;
    pso: string;
    onSubmit: (values: any, imgSm: string, imgLg: string) => void;
    
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ email, pso, onSubmit }) => {
    const navigate = useNavigate();

    const [ProfileImages, setProfileImages] = useState<any[]>([]);
    const [LoadingGalleryPerfil, setLoadingGalleryPerfil] = useState(true);
    const [currentImageSrc, setCurrentImageSrc] = useState<string | null>(null);
    const [base64ImageSmall, setBase64ImageSmall] = useState<string | null>(null);
    const [base64ImageLarge, setBase64ImageLarge] = useState<string | null>(null);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);
    const [activeField, setActiveField] = useState<string | null>(null);
    const [TycContent, setTyc] = useState<string | null>(null);
    const [Fecha, setFecha] = useState<string | null>(null);

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const dispatch = useDispatch<AppDispatch>();

    const countryOptions = getNames().map((country) => ({
        label: country,
        value: country,
    }));

    const initialEmail = email || '';
    const initialGlobalUser = initialEmail ? initialEmail.split('@')[0] : '';
    const GALLERY_PROFILE: any = useAppSelectorBySlice('GetImageProfilePso');
    const TYC: any = useAppSelectorBySlice('GetTYC_Cf');

    useEffect(() => {
        const fetchGalleryPerfilImages = () => {
            const funcionquery = 'getImageProfilePso';
            const paramsquery = { id_q: 'GET_IMG_GALLERY_PERFIL_PSO', GlobalUser: pso, GlobalSize: '1600' };
            functionMap[funcionquery](paramsquery, dispatch);
        };
        const getTycCF = () => {
            const funcionquery = 'GetTYC_Cf';
            const paramsquery = { id_q: 'GET_TYC_CF'};
            functionMap[funcionquery](paramsquery, dispatch);
        };
        fetchGalleryPerfilImages();
        getTycCF();
    }, [dispatch]);

    useEffect(() => {
        if (GALLERY_PROFILE.isRsp) {
            const images = GALLERY_PROFILE.rsp.map((img: any) => ({
                img_data: `data:image/jpeg;base64,${img.img_data}`,
                cod_entidad: img.cod_entidad,
                rol_entidad: img.rol_entidad,
                img_tag: img.img_tag,
                img_text: img.img_text,
                img_id: img.img_id,
                visible: img.visible
            }));
            setProfileImages(images);
            setLoadingGalleryPerfil(false);
        }
    }, [GALLERY_PROFILE]);

    useEffect(()=>{
        if (TYC.isRsp) {
            const tyc = TYC.rsp[0].text_tyc;
            const fecha = TYC.rsp[0].fecha;
            setTyc(tyc);
            setFecha(fecha);
        }
    },[TYC])

    const formik = useFormik({
        initialValues: {
            GlobalNemoPersona: '',
            GlobalFechaNacimiento: '',
            GlobalFono: '',
            GlobalDireccion: '',
            GlobalNumeroDireccion: '',
            GlobalMail: initialEmail,
            GlobalPso: pso,
            GlobalUser: initialGlobalUser,
            GlobalRut: '',
            GlobalPais: null // Campo para el país
        },
        validationSchema: Yup.object({
            GlobalNemoPersona: Yup.string().required('Required'),
            GlobalFechaNacimiento: Yup.date().required('Required'),
            GlobalFono: Yup.string().required('Required'),
            GlobalDireccion: Yup.string().required('Required'),
            GlobalNumeroDireccion: Yup.string().required('Required'),
            GlobalMail: Yup.string().email('Invalid email').required('Required'),
            GlobalRut: Yup.string().required('Required'),
            GlobalPais: Yup.object().nullable().required('Required') // Validación para el país
        }),
        onSubmit: (values: any) => {
            onSubmit(values, base64ImageLarge as string, base64ImageLarge as string);
            navigate('/bienvenida');
        },
    });

    const resizeImage = (
        dataUrl: string,
        maxWidth: number,
        callback: (resizedBase64: string) => void
    ) => {
        const img = new Image();
        img.src = dataUrl;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const aspectRatio = img.width / img.height;
            const width = maxWidth;
            const height = maxWidth / aspectRatio;

            canvas.width = width;
            canvas.height = height;

            ctx?.drawImage(img, 0, 0, width, height);
            const base64String = canvas.toDataURL('image/jpeg').replace(/^data:image\/jpeg;base64,/, '');
            callback(base64String);
        };
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result as string;
                img.onload = () => {
                    resizeImage(reader.result as string, 200, setBase64ImageSmall);
                    resizeImage(reader.result as string, 1600, setBase64ImageLarge);
                    setCurrentImageSrc(reader.result as string);
                };
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadImage = async () => {
        const actionDBsetImgServ = 'SetImageServAtencion';
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const paramsSetImgServ = {
            var_img_sd: base64ImageSmall,
            var_img_hd: base64ImageLarge,
        };
        functionMap[actionDBsetImgServ](paramsSetImgServ, dispatch);

        setCurrentImageSrc(null);
    };

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFieldFocus = (field: string) => {
        setActiveField(field);
    };

    const handleFieldBlur = () => {
        setActiveField(null);
    };

    const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTermsAccepted(event.target.checked);
    };

    const handleTermsOpen = () => {
        setTermsOpen(true);
    };

    const handleTermsClose = () => {
        setTermsOpen(false);
    };

    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            zIndex: 9999,
        }),
    };

    const customPhoneInputStyles: React.CSSProperties = {
        position: 'relative' as 'relative', // Tipo de CSS específico
        fontSize: '14px',
        marginTop: 0, // Usando un número en lugar de una cadena para 0
        marginBottom: 0, // Usando un número en lugar de una cadena para 0
        paddingLeft: '48px',
        marginLeft: 0, // Usando un número en lugar de una cadena para 0
        height: '35px',
        width: 'auto'
    };


    const TermsAndConditions = ({ Tyc }: { Tyc: string }) => {
        return (
            <Box sx={{ p: 2 }}>
                {Tyc.split('\n').map((line, index) => {
                    if (line.match(/^\d+\./)) {
                        return (
                            <Typography key={index} variant="h6" sx={{ mt: 2, mb: 1 }}>
                                {line}
                            </Typography>
                        );
                    } else {
                        return (
                            <Typography key={index} variant="body1" sx={{ mb: 1, textAlign: 'justify' }}>
                                {line}
                            </Typography>
                        );
                    }
                })}
            </Box>
        );
    };


    const CustomTypography = ({ text }: { text: string }) => {
        return (
            <Typography
                variant="caption"
                sx={{
                    color: '#fff', // Color de la letra blanca
                    textShadow: `
                        -1px -1px 2px #000, 
                        1px 1px 2px #000, 
                        1px -1px 2px #000, 
                        -1px 1px 2px #000`, // Borde negro más sutil con volumen
                    fontWeight: '600', // Grosor intermedio para un toque elegante
                    letterSpacing: '0.5px', // Espaciado de letras más refinado
                    fontSize: '1rem', // Ajuste de tamaño para un aspecto elegante
                }}
            >
                {text}
            </Typography>
        );
    };
    return (
        <div className="registro-container">
            {/* Espacio para la imagen de la marca */}
            <div className="brand-logo">
                <img src="/path-to-your-logo.png" alt="Brand Logo" className="brand-image" />
            </div>
            
            <div className="registro-example-image-section">
                <div className="registro_img_text">
                    {LoadingGalleryPerfil ? (
                        <img
                            src="https://via.placeholder.com/150"
                            alt="User Profile"
                            className="perfil_pso-image"
                        />
                    ) : (
                        <div className="perfil_pso-imgPerfil">
                            <img
                                src={ProfileImages.find((img: any) => img.visible == '1').img_data}
                                alt="User Profile"
                                className="perfil_pso-image"
                            />
                            
                            <CustomTypography text={pso} />
                        </div>
                    )}
                </div>
                <div>
                    <div className="perfil_pso-imgPerfil">
                        {currentImageSrc && (
                            <img
                                src={currentImageSrc}
                                alt="Example"
                                className="registro-image-perfil"
                                onClick={triggerFileInput}
                            />
                        )}
                        {!currentImageSrc && (
                            <div className="ImageEditModal-add-image">
                                <button onClick={triggerFileInput} className="ImageEditModal-add-image-button">
                                    +
                                </button>
                            </div>
                        )}
                        <CustomTypography text={formik.values.GlobalUser} />

                    </div>
                </div>
            </div>

            <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                className="registro-textfield"
                                label="Nick Name"
                                id="GlobalNemoPersona"
                                name="GlobalNemoPersona"
                                type="text"
                                value={formik.values.GlobalNemoPersona}
                                onChange={formik.handleChange}
                                onFocus={() => handleFieldFocus('GlobalNemoPersona')}
                                onBlur={handleFieldBlur}
                                error={formik.touched.GlobalNemoPersona && Boolean(formik.errors.GlobalNemoPersona)}
                                margin="normal"
                                fullWidth
                            />
                            <Collapse in={activeField === 'GlobalNemoPersona'}>
                                <Typography variant="caption" color="textSecondary">
                                    El Nick Name es como te identificas y te buscarán en la plataforma.
                                </Typography>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="registro-fecha"
                                label="Fecha de Nacimiento"
                                id="GlobalFechaNacimiento"
                                name="GlobalFechaNacimiento"
                                type="date"
                                value={formik.values.GlobalFechaNacimiento}
                                onChange={formik.handleChange}
                                onFocus={() => handleFieldFocus('GlobalFechaNacimiento')}
                                onBlur={handleFieldBlur}
                                error={formik.touched.GlobalFechaNacimiento && Boolean(formik.errors.GlobalFechaNacimiento)}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <Collapse in={activeField === 'GlobalFechaNacimiento'}>
                                <Typography variant="caption" color="textSecondary">
                                    La fecha de nacimiento es necesaria para premios y promociones.
                                </Typography>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                options={countryOptions}
                                name="GlobalPais"
                                value={formik.values.GlobalPais}
                                onChange={(option) => formik.setFieldValue('GlobalPais', option)}
                                onFocus={() => handleFieldFocus('GlobalPais')}
                                onBlur={handleFieldBlur}
                                placeholder="Selecciona tu país"
                                styles={customStyles}
                            />
                            <Collapse in={activeField === 'GlobalPais'}>
                                <Typography variant="caption" color="textSecondary">
                                    Selecciona tu país de residencia.
                                </Typography>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneInput
                                country={'cl'}
                                value={formik.values.GlobalFono}
                                onChange={(value) => formik.setFieldValue('GlobalFono', value)}
                                inputProps={{
                                    name: 'GlobalFono',
                                    required: true,
                                    autoFocus: false
                                }}
                                
                                inputClass="registro-phone"
                                containerClass="custom-phone-input"
                                onFocus={() => handleFieldFocus('GlobalFono')}
                                onBlur={handleFieldBlur}
                                inputStyle={customPhoneInputStyles}
                            />
                            <Collapse in={activeField === 'GlobalFono'}>
                                <Typography variant="caption" color="textSecondary">
                                    El número de teléfono es para informarte sobre el estado de tu agendamiento y comunicarte cualquier eventualidad.
                                </Typography>
                            </Collapse>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                className="registro-textfield"
                                label="Dirección"
                                id="GlobalDireccion"
                                name="GlobalDireccion"
                                type="text"
                                value={formik.values.GlobalDireccion}
                                onChange={formik.handleChange}
                                onFocus={() => handleFieldFocus('GlobalDireccion')}
                                onBlur={handleFieldBlur}
                                error={formik.touched.GlobalDireccion && Boolean(formik.errors.GlobalDireccion)}
                                margin="normal"
                                fullWidth
                            />
                            <Collapse in={activeField === 'GlobalDireccion'}>
                                <Typography variant="caption" color="textSecondary">
                                    Tu dirección es necesaria para proporcionarte información sobre los servicios más cercanos a ti.
                                </Typography>
                            </Collapse>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                className="registro-textfield"
                                label="Número de Dirección"
                                id="GlobalNumeroDireccion"
                                name="GlobalNumeroDireccion"
                                type="text"
                                value={formik.values.GlobalNumeroDireccion}
                                onChange={formik.handleChange}
                                onFocus={() => handleFieldFocus('GlobalNumeroDireccion')}
                                onBlur={handleFieldBlur}
                                error={formik.touched.GlobalNumeroDireccion && Boolean(formik.errors.GlobalNumeroDireccion)}
                                margin="normal"
                                fullWidth
                            />
                            
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="registro-RUT"
                                label="RUT"
                                id="GlobalRut"
                                name="GlobalRut"
                                type="text"
                                value={formik.values.GlobalRut}
                                onChange={formik.handleChange}
                                onFocus={() => handleFieldFocus('GlobalRut')}
                                onBlur={handleFieldBlur}
                                error={formik.touched.GlobalRut && Boolean(formik.errors.GlobalRut)}
                                margin="normal"
                                fullWidth
                            />
                            <Collapse in={activeField === 'GlobalRut'}>
                                <Typography variant="caption" color="textSecondary">
                                    El RUT es necesario para verificar tu identidad en la plataforma.
                                </Typography>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="TextDisable"
                                label="Mail"
                                id="GlobalMail"
                                name="GlobalMail"
                                type="email"
                                value={formik.values.GlobalMail}
                                onChange={formik.handleChange}
                                onFocus={() => handleFieldFocus('GlobalMail')}
                                onBlur={handleFieldBlur}
                                error={formik.touched.GlobalMail && Boolean(formik.errors.GlobalMail)}
                                margin="normal"
                                fullWidth
                                disabled
                            />
                            <Collapse in={activeField === 'GlobalMail'}>
                                <Typography variant="caption" color="textSecondary">
                                    Este es el correo electrónico que utilizas para iniciar sesión.
                                </Typography>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="TextDisable"
                                label="PSO ID"
                                id="GlobalPso"
                                name="GlobalPso"
                                type="text"
                                value={formik.values.GlobalPso}
                                onChange={formik.handleChange}
                                onFocus={() => handleFieldFocus('GlobalPso')}
                                onBlur={handleFieldBlur}
                                margin="normal"
                                fullWidth
                                disabled
                            />
                            <Collapse in={activeField === 'GlobalPso'}>
                                <Typography variant="caption" color="textSecondary">
                                    Este es el ID único que se asocia con tu cuenta PSO.
                                </Typography>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="TextDisable"
                                label="GlobalUser"
                                id="GlobalUser"
                                name="GlobalUser"
                                type="text"
                                value={formik.values.GlobalUser}
                                onChange={formik.handleChange}
                                onFocus={() => handleFieldFocus('GlobalUser')}
                                onBlur={handleFieldBlur}
                                margin="normal"
                                fullWidth
                                disabled
                            />
                            <Collapse in={activeField === 'GlobalUser'}>
                                <Typography variant="caption" color="textSecondary">
                                    Este es tu nombre de usuario que se generó automáticamente.
                                </Typography>
                            </Collapse>
                        </Grid>

                        {/* Checkbox de términos y condiciones */}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={termsAccepted}
                                        onChange={handleTermsChange}
                                        color="primary"
                                    />
                                }
                                label={
                                    <>
                                        Acepto los{' '}
                                        <Button onClick={handleTermsOpen} variant="text" color="primary">
                                            Términos y Condiciones
                                        </Button>
                                    </>
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ mt: 3 }}
                                disabled={!termsAccepted} // Deshabilitar el botón si no se aceptan los términos
                            >
                                Registrar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>

            <Dialog open={termsOpen} onClose={handleTermsClose}>
                <DialogTitle>Términos y Condiciones</DialogTitle>
                {Fecha && (
                            <DialogTitle>Actualizado : {Fecha}</DialogTitle>
                        )}
                <DialogContent>
                    <Typography variant="body1">
                    {TycContent && (
                            <TermsAndConditions Tyc={TycContent as string} />
                        )}
                    
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTermsClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            <div>
                <input type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
            </div>
        </div>
    );
};

export default RegistrationForm;
