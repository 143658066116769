
//   ------------------------------------------------
export type MenuGetConexionData = {
    pso: string;
    localidad: string;
    sede: string;
    pso_nemo: string;
    loc_nemo: string;
    sede_nemo: string;

}


export enum MenuGetConexionTypes {
    GET_MENUPSO_LIST_CONX = 'GET_MENUPSO_LIST_CONX',
}

export type MenuGetConexionState = {
    rsp: MenuGetConexionData[] | null;
    isLoading: boolean;
    isError: boolean;
    isRsp:boolean;
    error: string | null | unknown;
};


//   ------------------------------------------------
export type GetMenuPsoListPsoData = {
    cod_pso: string;
    rut_cf: string;
    dv_cf: string;
    cod_cf: string;

}


export enum GetMenuPsoListPsoTypes {
    GET_MENUPSO_LIST_PSO = 'GET_MENUPSO_LIST_PSO',
}

export type GetMenuPsoListPsoState = {
    rsp: GetMenuPsoListPsoData[] | null;
    isLoading: boolean;
    isError: boolean;
    error: string | null | unknown;
};

//   ------------------------------------------------

export type GetMenuPsoListPSwData = {
    pso: string;
    localidad: string;
    sede: string;
    pso_nemo: string;
    loc_nemo: string;
    sede_nemo: string;
  
  }
  
  
  export enum GetMenuPsoListPSwTypes {
    GET_MENUPSO_LIST_PSW = 'GET_MENUPSO_LIST_PSW',
  }
  
  export type GetMenuPsoListPSwState = {
    rsp: GetMenuPsoListPSwData[] | null;
    isLoading: boolean;
    isError: boolean;
    error: string | null | unknown;
  };
//   ----------------------------------------------------------
export type GetMenuPsoListPswPsoData = {
    pso: string;
    localidad: string;
    sede: string;
    pso_nemo: string;
    loc_nemo: string;
    sede_nemo: string;
  
  }
  
  
  export enum GetMenuPsoListPswPsoTypes {
    GET_MENUPSO_LIST_PSW_PSO = 'GET_MENUPSO_LIST_PSW_PSO',
  }
  
  export type GetMenuPsoListPswPsoState = {
    rsp: GetMenuPsoListPswPsoData[] | null;
    isLoading: boolean;
    isRsp: boolean;
    isError: boolean;
    error: string | null | unknown;
  };