import React, { useEffect } from 'react';

declare global {
    interface Window {
        google: any;
    }
}

const GoogleAuth: React.FC<{ onSuccess: (user: any) => void }> = ({ onSuccess }) => {
    useEffect(() => {
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: '378111053-lddpk9oots3hor6ffmjebsh4e7jlch0p.apps.googleusercontent.com',
                callback: handleCredentialResponse,
            });

            window.google.accounts.id.renderButton(
                document.getElementById('google-signin-button'),
                {
                    theme: 'outline',
                    size: 'large',
                }
            );
        }

        function handleCredentialResponse(response: any) {
            const user = parseJwt(response.credential);
            onSuccess(user);
        }

        function parseJwt(token: string) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        }
    }, [onSuccess]);

    return <div id="google-signin-button"></div>;
};

export default GoogleAuth;
