import React from 'react';
import { Button, Box, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Registro.css'; // Importar el archivo de estilos

interface MsgBienvenidaClienteFormProps {
    pso: string;
}

const MsgBienvenidaClienteForm: React.FC<MsgBienvenidaClienteFormProps> = ({ pso }) => {
    const navigate = useNavigate(); // Hook para la navegación

    const handleAccept = () => {
        navigate('/home'); // Redirigir a la página de inicio o donde sea necesario
    };

    return (
        <Box className="registro-container">
            <Typography variant="h4" component="h1" className="registro-title">
                Bienvenida {pso}
            </Typography>
            <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                <Typography variant="h5" component="h2" gutterBottom textAlign="center">
                ¡Registro completado con éxito! Nos emociona darte la bienvenida. Ya puedes comenzar a agendar tus servicios con nosotros. ¡Estamos aquí para lo que necesites!
                </Typography>
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Button variant="contained" color="primary" onClick={handleAccept}>
                        Aceptar
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default MsgBienvenidaClienteForm;
